import React, { HTMLProps } from 'react'
import { scrollToHash } from 'util/link'
import { fromModule } from 'util/styler/Styler'
import css from './mousescroll.module.scss'

const styles = fromModule(css)

export const Mousescroll: React.FC<HTMLProps<HTMLAnchorElement> & {
	id: string
}> = ({ id, ...props }) => (
	<span
		className={styles.mousescroll.mergeProps(props)()}
		onClick={() => scrollToHash(id)}
	/>
)
