import {DPHierarchy, DPMenuTerm} from 'types/DPTypes'
import {HeaderNav, HeaderNavDropdown} from './headernav'
import React, {Fragment, useEffect, useState} from 'react'

import {HeaderMobilenav} from './headermobilenav'
import {Icon} from 'assets/icon'
import Image from 'next/image'
import {Link} from 'util/link'
import {Theme} from 'layout/theme'
import css from './header.module.scss'
import dynamic from 'next/dynamic'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useLinks} from 'util/links'
import {useRouter} from 'next/router'
import {useTranslation} from 'util/i18'

const Dialog = dynamic(() => import('@material-ui/core/Dialog'), {ssr: false})
const HeaderSearchInput = dynamic(
	() => import('./headersearchinput').then((mod) => mod.HeaderSearchInput),
	{ssr: false}
)

export type HeaderLinkType = {
	title: string
	url: string
	id: string
	children: Array<{
		title: string
		url: string
		description: string
	}>
}

const styles = fromModule(css)

export const Header: React.FC<{
	items: DPHierarchy<DPMenuTerm>
}> = ({items}) => {
	const [searchisOpen, setSearchOpen] = useState<boolean>(false)
	const [hoveringID, setHoveringID] = useState<number>(null)
	const hoveringItem = items.find((item) => item.id === hoveringID)
	const setHoveringItem = (item) => setHoveringID(item ? item.id : null)

	const {home, contact} = useLinks()
	const {
		header: {request}
	} = useTranslation()
	const envProjectKey = process.env.NEXT_PUBLIC_PROJECT_KEY
	const subsite =
		envProjectKey === 'hyperspectral' || envProjectKey === 'iclink'

	return (
		<header
			className={styles.header()}
			onMouseLeave={() => setHoveringItem(null)}
		>
			<div className={styles.header.rowcontainer()}>
				<Theme.OuterContainer>
					<div className={styles.header.row()}>
						<div className={styles.header.row.left()}>
							{subsite ? <HeaderHome /> : <HeaderLogo url={home?.url} />}
							<HeaderNav items={items} setHoveringItem={setHoveringItem} />
						</div>
						<div className={styles.header.row.right()}>
							{!subsite && !searchisOpen && (
								<Link
									to={contact.url}
									className={styles.header.row.right.link()}
								>
									<span className={styles.header.row.right.link.text()}>
										{contact.title}
									</span>
								</Link>
							)}
							{/* <HeaderSearchElastic
								searchisOpen={searchisOpen}
								onSearchClick={(searchisOpen) => setSearchOpen(searchisOpen)}
							/> */}
							{!subsite && <HeaderSearchAlgolia
								searchisOpen={searchisOpen}
								onSearchClick={(searchisOpen) => setSearchOpen(searchisOpen)}
							/>}
							{subsite && <HeaderLogo />}
							{subsite && (
								<Theme.Button
									to={contact?.url}
									className={styles.header.button()}
									mod="rounded"
								>
									<Theme.ButtonText>{request}</Theme.ButtonText>
									<Theme.ButtonIcon />
								</Theme.Button>
							)}
							<HeaderHamburger items={items} />
						</div>
					</div>
				</Theme.OuterContainer>
			</div>
			{items.map((item) => (
				<div
					key={item.id}
					className={styles.header.dropdowncontainer.is({
						open: item === hoveringItem
					})()}
					onMouseOver={() => setHoveringItem(item)}
					onMouseLeave={() => setHoveringItem(null)}
				>
					<HeaderNavDropdown item={item} isOpen={item === hoveringItem} />
				</div>
			))}
		</header>
	)
}

const HeaderHome: React.FC = () => {
	const {home} = useLinks()

	return (
		<Link
			to={home?.url}
			className={styles.header_home()}
			aria-label="Home"
			alt="Home"
		>
			<Icon icon="Home" />
		</Link>
	)
}

export const HeaderLogo: React.FC<{url?: string}> = ({url}) => {
	return (
		<Link
			className={styles.header_logo()}
			to={url || 'https://www.imec-int.com/'}
			aria-label="Home"
			alt="Home"
		>
			<Image
				src="/assets/img/imec-logo.svg"
				width={373}
				height={112.4}
				alt="imec logo"
				className={styles.header_logo.img()}
			/>
		</Link>
	)
}

export const HeaderSearchElastic: React.FC<{
	searchisOpen: boolean
	onSearchClick: (searchisOpen: boolean) => void
}> = ({searchisOpen, onSearchClick}) => {
	const [suggestionText, setSuggestionText] = useState('')
	const {search} = useLinks()
	const router = useRouter()
	const href = search.url + '?query=' + suggestionText
	return (
		<div className={styles.header_search()}>
			{!searchisOpen && (
				<button
					className={styles.header_search.button()}
					onClick={() => onSearchClick(true)}
				>
					<Icon icon="Search" />
				</button>
			)}
			{searchisOpen && (
				<form
					onSubmit={(e) => {
						e.preventDefault()
						router.push(href)
					}}
					onBlur={() =>
						setTimeout(() => {
							onSearchClick(false)
						}, 100)
					}
					className={styles.header_search.active()}
				>
					<input
						autoFocus
						className={styles.header_search.active.input()}
						name="search"
						type="text"
						onChange={(e) => setSuggestionText(e.target.value)}
					></input>
					<button
						className={styles.header_search.active.button()}
						type="submit"
						value="search"
					>
						<Icon icon="Search" />
					</button>
				</form>
			)}
		</div>
	)
}

export const HeaderSearchAlgolia: React.FC<{
	searchisOpen: boolean
	onSearchClick: (searchisOpen: boolean) => void
}> = ({searchisOpen, onSearchClick}) => {
	const {lang} = useApp()
	const t = useTranslation()

	return (
		<div className={styles.header_search()}>
			{!searchisOpen && (
				<button
					title="Search"
					aria-label={t.header.search.toggle_arialabel}
					aria-hidden={searchisOpen ? true : false}
					onClick={() => onSearchClick(true)}
					className={styles.header_search.button()}
				>
					<Icon icon="Search" />
				</button>
			)}
			{searchisOpen && <HeaderSearchInput />}
		</div>
	)
}

export const HeaderHamburger: React.FC<{
	items: DPHierarchy<DPMenuTerm>
}> = ({items}) => {
	const {asPath} = useRouter()
	const [isOpenMenu, setMenu] = useState(false)
	const t = useTranslation()

	useEffect(() => {
		setMenu(false)
	}, [asPath])

	return (
		<Fragment>
			<a
				href="#"
				className={styles.header_hamburger()}
				onClick={() => setMenu(!isOpenMenu)}
				aria-expanded={isOpenMenu ? true : false}
				aria-label={t.header.hamburger.open_arialabel}
			>
				<span className={styles.header_hamburger.bar()} />
				<span className={styles.header_hamburger.bar()} />
				<span className={styles.header_hamburger.bar()} />
			</a>
			<Dialog
				open={isOpenMenu}
				onClose={() => setMenu(!isOpenMenu)}
				aria-hidden={isOpenMenu ? false : true}
			>
				<HeaderMobilenav items={items} onCloseMenu={() => setMenu(false)} />
			</Dialog>
		</Fragment>
	)
}
