import { JobFilterKey, JobFilterModel, JobFilterState, jobRegularFilterKeys } from 'blocks/jobs/models/JobFilterModel'
import { JobModel, JobState } from 'blocks/jobs/models/JobModel'
import { RegularJobTermState, TaxonomyFilterModel } from 'blocks/jobs/models/filters/TaxonomyFilterModel'

import { DatePostedFilterModel } from 'blocks/jobs/models/filters/DatePostedFilterModel'
import { JobTermModel } from 'blocks/jobs/models/JobTermModel'
import type { JobsData } from 'blocks/jobs/jobs'
import type { SearchParams } from 'util/useSearchParams'
import memoizeGetters from 'memoize-getters'

export type JobsQueryState = {
	category: JobsCategory
	query: null | { query: string, result?: Array<string> }
	terms: Array<string>
	filters: Record<JobFilterKey, RegularJobTermState[]>
	jobs: Array<JobState>
	bookmarked: Array<string>
	csc: boolean
}

export type JobsCategory = 'career' | 'academic' | 'bookmarked'

export class JobsSearchModel {
	state: JobsQueryState

	constructor(state: JobsQueryState) {
		this.state = state
	}

	static createFromParams(data: JobsData, params: SearchParams) {
		const mapping = []
		Object.keys(data.types).forEach(key => {
			data.types[key].forEach(term => {
				mapping[term._tid] = term.tid
			})
		})
		const terms = params.filters.map(filter => {
			if (isNaN(filter as any)) return filter
			return mapping[filter]
		})

		return new JobsSearchModel({
			category: params.type as JobsCategory,
			query: { query: params.query },
			terms: terms,
			filters: data.types,
			csc: data.csc,
			jobs: data.items,
			bookmarked: JSON.parse(localStorage.bookmarked_jobs || '[]')
		})
	}

	get needsQuerySearchResults(): boolean {
		return this.query && !this.state.query?.result
	}

	get query(): string {
		return this.state.query?.query
	}

	get csc(): boolean {
		return !!this.state.csc
	}

	get allowedCtsIdsByFilter(): Array<string> | null {
		if (!this.query || this.needsQuerySearchResults) return null
		return this.state.query?.result
	}

	get category(): JobsCategory {
		if (this.state.category !== 'bookmarked' && this.csc) return 'academic'
		if (!this.state.category) return 'career'
		return this.state.category
	}

	get numberOfSelectedTerms(): number {
		return this.selectedTerms.length
	}

	get selectedTerms(): Array<JobTermModel> {
		return this.terms.filter(tag => tag.selected)
	}

	get terms(): Array<JobTermModel> {
		let result = []
		this.filters.forEach(filter => {
			result = [...result, ...filter.terms]
		})
		return result
	}

	get bookmarked(): Array<JobModel> {
		return this.allJobs.filter(job => job.isBookmarked)
	}

	get filters(): Array<JobFilterModel> {
		switch (this.category) {
			case 'career':
				const careerKeys: JobFilterKey[] = ['type', 'job_title', 'categories', 'region', 'date_posted', 'employment_type', 'employer']
				return careerKeys.map(key => this.allFilters.find(filter => filter.key === key))

			case 'academic':
				const academicKeys: JobFilterKey[] = ['employment_type', 'categories', 'region', 'date_posted', 'csr', 'promotor', 'competence']
				return academicKeys.map(key => this.allFilters.find(filter => filter.key === key))

			case 'bookmarked':
				return []

			default:
				console.warn(`Unknown category: ${this.category}`)
				return []
		}
	}

	get allRegularFilters(): Array<JobFilterModel> {
		return jobRegularFilterKeys.map(key => {
			const state: JobFilterState = { key, terms: this.state.filters[key] }
			return new TaxonomyFilterModel(this, state)
		})
	}

	get allFilters(): Array<JobFilterModel> {
		return [
			...this.allRegularFilters,
			new DatePostedFilterModel(this)
		]
	}

	get jobs(): Array<JobModel> {
		if (this.category === 'bookmarked') return this.bookmarked

		if (this.allowedCtsIdsByFilter) {
			const jobsMap = new Map()
			this.allJobs.forEach(job => jobsMap.set(job.ctsId, job))
			return this.allowedCtsIdsByFilter
				.map(ctsID => jobsMap.get(ctsID))
				.filter(job => job && job.isValid)
		}

		return this.allJobs.filter(job => {
			return job.isValid
		})
	}

	get allJobs(): Array<JobModel> {
		return this.state.jobs.map(state => new JobModel(this, state))
	}

	// ----- Update state ----- //
	update(newState: Partial<JobsQueryState>): JobsSearchModel {
		return new JobsSearchModel({
			...this.state,
			...newState
		})
	}

	clear() {
		return this.update({
			terms: []
		})
	}

	setCategory(category: JobsCategory) {
		return this.update({
			category
		})
	}

	setQuery(query: string) {
		return this.update({
			query: { query }
		})
	}

	setQueryResults(query: string, result: Array<string>) {
		if (!query || this.query !== query) return this
		return this.update({
			query: {
				query,
				result
			}
		})
	}
}
memoizeGetters(JobsSearchModel)
