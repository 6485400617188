import {Block} from 'blocks/block'
import {DPProcessBlock} from 'types/DPTypes'
import {Link} from 'util/link'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './process.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Process: React.FC<DPProcessBlock> = ({_type, text, links}) => {
	const hasLinks = links && links.length > 0
	if (!text && !hasLinks) return null

	return (
		<Block type={_type} className={styles.process.mod({links: hasLinks})()}>
			<Theme.Container>
				{text && (
					<div className={styles.process.text()}>
						<Theme.Wysiwyg>{text}</Theme.Wysiwyg>
					</div>
				)}
			</Theme.Container>
			{hasLinks && (
				<div className={styles.process.links()}>
					<Theme.Container>
						<div className={styles.process.links.items()}>
							{links.map((link, i) => (
								<div key={i} className={styles.process.links.item()}>
									<Link to={link.url} className={styles.process.links.link()}>
										{link.title}
									</Link>
								</div>
							))}
						</div>
					</Theme.Container>
				</div>
			)}
		</Block>
	)
}
