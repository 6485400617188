import {Column, Grid} from 'layout/grid'
import {JobModel, JobState} from 'blocks/jobs/models/JobModel'
import {JobsCategory, JobsSearchModel} from 'blocks/jobs/models/JobsSearchModel'
import React, {
	HTMLProps,
	createContext,
	useContext,
	useEffect,
	useState
} from 'react'
import {SearchbarInput, SearchbarToggler} from 'layout/search/searchbar'
import {
	SearchresultsLoader,
	SearchresultsMore
} from 'layout/search/searchresults'

import AnimateHeight from 'react-animate-height'
import {Block} from 'blocks/block'
import {DPJobsBlock} from 'types/DPTypes'
import {Icon} from 'assets/icon'
import {JOBS_SEARCH_URL} from 'util/urls'
import {JobAlerts} from 'blocks/jobs/jobalerts'
import {JobFilterKey} from 'blocks/jobs/models/JobFilterModel'
import {Jobsfilters} from 'blocks/jobs/jobsfilters'
import {Link} from 'util/link'
import {RegularJobTermState} from 'blocks/jobs/models/filters/TaxonomyFilterModel'
import {Theme} from 'layout/theme'
import css from './jobs.module.scss'
import {fromModule} from 'util/styler/Styler'
import {getJobtitleString} from 'nodes/job'
import unfetch from 'unfetch'
import {useSearchParams} from 'util/useSearchParams'
import {useSearchParamsAreLoaded} from 'util/useSearchParamsAreLoaded'

const styles = fromModule(css)

export type JobsData = {
	csc: boolean
	types: Record<JobFilterKey, RegularJobTermState[]>
	items: JobState[]
}

export type JobsContextType = {
	search: JobsSearchModel
	updateSearch: (newState: JobsSearchModel) => void
}

export type JobsBlockData = {
	csc_opportunities: boolean
}

export const JobsContext = createContext<JobsContextType>(null)

function performSearch(search: JobsSearchModel): Promise<JobsSearchModel> {
	const url = JOBS_SEARCH_URL({query: search.query})
	return unfetch(url)
		.then((resp) => resp.json())
		.then((data) => search.setQueryResults(search.query, data))
}

export const Jobs: React.FC<DPJobsBlock> = (data) => {
	const loaded = useSearchParamsAreLoaded()

	if (!loaded) return <SearchresultsLoader />
	return (
		<Block type={data._type}>
			<JobsView {...data} />
		</Block>
	)
}

export const JobsView: React.FC<DPJobsBlock> = ({
	csc_opportunities: csc,
	types,
	items
}) => {
	const params = useSearchParams()
	const data = {
		csc, //TODO next: check json data for this situation
		types: types as any, //TODO: fix types
		items
	}
	const {page, updateParams} = params
	const [search, setSearch] = useState<JobsSearchModel>(() => {
		return JobsSearchModel.createFromParams(data, params)
	})
	const updateSearch = (newSearch: JobsSearchModel) => {
		setSearch(newSearch)
		localStorage.bookmarked_jobs = JSON.stringify(
			newSearch.bookmarked.map((job) => job.id)
		)
		const resetPage =
			search.state.bookmarked.length === newSearch.state.bookmarked.length
		params.updateParams({
			type: newSearch.state.category,
			filters: newSearch.state.terms,
			query: newSearch.query,
			page: resetPage ? 0 : page
		})
	}

	useEffect(() => {
		if (search?.needsQuerySearchResults) {
			performSearch(search).then((newSearch) => setSearch(newSearch))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search?.query, search?.needsQuerySearchResults])

	const nrOfItems = (page + 1) * 12
	const visibleJobs = (search?.jobs || []).slice(0, nrOfItems)
	const showMore = search?.jobs?.length > nrOfItems
	const isLoading = !search || search.needsQuerySearchResults

	return (
		<div className={styles.jobs()}>
			<JobsContext.Provider value={{search, updateSearch}}>
				<JobsTabs />
				<JobsSearch />
				{isLoading && (
					<div className={styles.jobs.results()}>
						<SearchresultsLoader />
					</div>
				)}
				{!isLoading && (
					<div className={styles.jobs.results()}>
						{visibleJobs.map((job) => (
							<JobsItem job={job} key={job.url} />
						))}
					</div>
				)}
				{!isLoading && showMore && (
					<SearchresultsMore onClick={() => updateParams({page: page + 1})}>
						LOAD MORE ({search.jobs.length - nrOfItems})
					</SearchresultsMore>
				)}
			</JobsContext.Provider>
		</div>
	)
}

export const JobsTabs: React.FC = () => {
	const {search, updateSearch} = useContext(JobsContext)
	const {type, createUrlObject} = useSearchParams()

	let tabs = []
	if (search && !search.csc) {
		tabs = [
			{key: 'career', icon: 'Career' as const, label: 'Career opportunities'},
			{
				key: 'academic',
				icon: 'Academic' as const,
				label: 'Academic opportunities'
			}
		]
	}

	return (
		<div className={styles.tabs()}>
			<Theme.Container>
				<div className={styles.tabs.tabs()}>
					<div className={styles.tabs.tabs.group.mod('items')()}>
						{tabs.map((tab) => (
							<Link
								to={createUrlObject({type: tab.key})}
								key={tab.key}
								onClick={(e) => {
									if (!search) return
									updateSearch(search.setCategory(tab.key as JobsCategory))
									e.preventDefault()
									return false
								}}
								className={styles.tabs.item.is({
									active: type === tab.key || (!type && tab.key == 'career')
								})()}
							>
								<Icon
									className={styles.tabs.item.icon()}
									icon={tab.icon}
									nofill={true}
								/>
								{tab.label}
							</Link>
						))}
					</div>
					<div className={styles.tabs.tabs.group.mod('bookmarked')()}>
						{search && (
							<Link
								to={createUrlObject({type: 'bookmarked'})}
								onClick={(e) => {
									if (!search) return
									updateSearch(
										search.setCategory(
											search.category !== 'bookmarked' ? 'bookmarked' : null
										)
									)
									e.preventDefault()
									return false
								}}
								className={styles.tabs.bookmark.is({
									selected: type === 'bookmarked'
								})()}
							>
								<JobsBookmarkIcon active={search.bookmarked.length > 0} />
								{search.bookmarked.length} jobs saved
							</Link>
						)}
					</div>
				</div>
			</Theme.Container>
		</div>
	)
}

export const JobsSearch: React.FC = () => {
	const {search, updateSearch} = useContext(JobsContext)
	const [open, setOpen] = useState(false)

	return (
		<div className={styles.search()}>
			<div className={styles.search.query()}>
				<Theme.Container>
					<Grid columns={1} s={1} m={12} l={12} align="top">
						<Column className={styles.search()} m={9} l={9}>
							<SearchbarInput
								onSearch={(query) => {
									if (!search) return
									updateSearch(search.setQuery(query))
								}}
								query={search?.query || ''}
								mod="dark"
							/>
						</Column>
						<Column m={3} l={3}>
							<SearchbarToggler open={open} onToggle={() => setOpen(!open)}>
								<span style={{marginRight: '10px'}}>
									<Icon icon="Bell" />
								</span>
								{open ? 'Hide criteria' : 'Notify me about new jobs'}
							</SearchbarToggler>
						</Column>
					</Grid>
					<div className={styles.search.criteria()}>
						<AnimateHeight height={open ? 'auto' : 0} animateOpacity={true}>
							<JobAlerts />
						</AnimateHeight>
					</div>
				</Theme.Container>
			</div>
			{search?.category !== 'bookmarked' && (
				<div className={styles.search.filters()}>
					<Theme.Container>
						<Jobsfilters />
					</Theme.Container>
				</div>
			)}
		</div>
	)
}

export const JobsBookmarkIcon: React.FC<
	{
		active: boolean
	} & HTMLProps<HTMLDivElement>
> = ({active, ...rest}) => {
	return (
		<Icon
			{...rest}
			icon={'Bookmark'}
			nofill={true}
			className={styles.bookmarkicon.mergeProps(rest).is({active})()}
		/>
	)
}

export const JobsItem: React.FC<{
	job: JobModel
}> = ({job}) => {
	const {updateSearch} = useContext(JobsContext)

	return (
		<div className={styles.item()}>
			<Theme.Container>
				<div className={styles.item.title()}>
					<Theme.H4>
						<Link to={job.url} className={styles.item.title.link()}>
							{job.title}
						</Link>
					</Theme.H4>
					<JobsBookmarkIcon
						active={!!job.isBookmarked}
						onClick={() => updateSearch(job.toggleBookmark())}
						title="Bookmark this job"
					/>
				</div>
				<div className={styles.item.subtitle()}>
					{getJobtitleString(job.category, job.location, job.date)}
				</div>
				{job.description && (
					<div className={styles.item.description()}>
						{job.description.replace(/<[^>]*>?/g, '')}
					</div>
				)}
			</Theme.Container>
		</div>
	)
}
