import {Column, Grid} from 'layout/grid'

import {BgFixed} from 'layout/partials/bg'
import {Block} from 'blocks/block'
import {DPBackgroundblockBlock} from 'types/DPTypes'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './backgroundblock.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useIDFromAnchorBlock} from 'blocks/anchor/anchor'

const styles = fromModule(css)

export const BackgroundBlock: React.FC<DPBackgroundblockBlock> = (data) => {
	const {_type, background, text_left, text_right, dark} = data
	const {node} = useApp()
	const id = useIDFromAnchorBlock(data)

	const gradient = !background && !dark
	const pageType = node?._type
	const darkText = background?.use_dark_colors

	return (
		<Block
			type={_type}
			anchorID={id}
			className={styles.backgroundblock
				.mod(pageType)
				.mod({dark})
				.mod({gradient})
				.mod({darkText})()}
		>
			<BgFixed image={background} />
			<Theme.Container>
				<div className={styles.backgroundblock.content()}>
					<Grid columns={1} xs={1} s={1} m={2} l={2}>
						<Column>
							<div className={styles.backgroundblock.content.left()}>
								{text_left && (
									<Theme.Wysiwyg mod={[pageType, 'inherit']}>
										{text_left}
									</Theme.Wysiwyg>
								)}
							</div>
						</Column>
						<Column>
							<div className={styles.backgroundblock.content.right()}>
								{text_right && (
									<Theme.Wysiwyg mod={[pageType, 'inherit']}>
										{text_right}
									</Theme.Wysiwyg>
								)}
							</div>
						</Column>
					</Grid>
				</div>
			</Theme.Container>
		</Block>
	)
}
