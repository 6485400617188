import {Column, Grid} from 'layout/grid'

import {Block} from 'blocks/block'
import {DPListBlock} from 'types/DPTypes'
import {Image} from 'layout/partials/image'
import React from 'react'
import {Theme} from 'layout/theme'
import {WysiwygType} from 'types/basictypes'
import css from './list.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const List: React.FC<DPListBlock> = (data) => {
	const {_type, title, items, image, display_single} = data
	if (!items || items.length === 0) return null
	const hasimage = image ? true : false

	return (
		<Block type={_type} className={styles.list()}>
			<Theme.Container>
				{title && (
					<Theme.H2 className={styles.list.title()} mod="blueline">
						{title}
					</Theme.H2>
				)}
				{!hasimage && (
					<ListItems items={items} display_single={display_single} />
				)}
				{hasimage && (
					<Grid columns={1} s={2} m={2} l={2} align="middle">
						<Column>
							<ListItems items={items} hasimage={hasimage} />
						</Column>
						<Column>
							<Image {...image} sizes="(max-width: 600px) 100vw, 640px" />
						</Column>
					</Grid>
				)}
			</Theme.Container>
		</Block>
	)
}

const ListItems: React.FC<{
	items: Array<WysiwygType>
	display_single?: boolean
	hasimage?: boolean
}> = ({items, display_single, hasimage}) => {
	if (!items || items.length === 0) return null

	return (
		<div
			className={styles.items
				.mod({fullwidth: display_single})
				.mod({hasimage})()}
		>
			{items.map((item, i) => (
				<div key={i} className={styles.items.item()}>
					<div className={styles.items.item.content()}>
						<span className={styles.items.item.content.checkmark()} />
						<span className={styles.items.item.content.text()}>
							<Theme.TrustedText>{item}</Theme.TrustedText>
						</span>
					</div>
				</div>
			))}
		</div>
	)
}
