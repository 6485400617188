import {DPInpagenavBlock, DPLink} from 'types/DPTypes'
import React, {useEffect, useRef, useState} from 'react'

import {Block} from 'blocks/block'
import {Link} from 'util/link'
import {Theme} from 'layout/theme'
import css from './inpagenav.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useAutohide} from 'util/autohide'

const styles = fromModule(css)

export type InpagenavType = {
	cta?: DPLink
	displayDark?: boolean
	links: Array<DPLink>
}

type AnchorType = {
	id: string
	title: string
}

export const Inpagenav: React.FC<DPInpagenavBlock> = (data) => {
	const {node} = useApp()
	const {_type, cta, links, display_dark = false} = data
	const [ignoreHide, setIgnoreHide] = useState(false)
	const {refFixed, refSticky} = useAutohide(ignoreHide)
	const [open, setOpen] = useState(false)
	const pageTitle = node?.title

	const openRef = useRef(open)
	useEffect(() => {
		openRef.current = open
	}, [open])

	useEffect(() => {
		let hideIt = null
		const scroll = () => {
			if (
				openRef.current &&
				refSticky.current &&
				refSticky.current.style.transform === `translateY(-100%)`
			) {
				hideIt = setTimeout(() => {
					setIgnoreHide(true)
					setOpen(false)
					setTimeout(() => setIgnoreHide(false), 1000)
				}, 200)
			} else {
				clearTimeout(hideIt)
			}
		}
		window.addEventListener('scroll', () => {
			scroll()
		})
		return () => {
			window.removeEventListener('scroll', scroll)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	let extraLinks = []
	extraLinks = ('blocks' in node ? node.blocks : [])
		.filter((bl) => bl && bl._type === 'anchor')
		.map((bl) => ({
			id: '#' + (bl as AnchorType).id,
			label: (bl as AnchorType).title
		}))

	if (!links?.length && !extraLinks.length && !cta) return null

	return (
		<Block type={_type}>
			<div ref={refFixed as any} className={styles.inpagenav.fixed()} />
			<div
				ref={refSticky as any}
				className={styles.inpagenav.is({dark: display_dark})()}
			>
				<div className={styles.inpagenav.row()}>
					<div>
						<a
							className={styles.inpagenav.hamburger()}
							onClick={() => {
								setIgnoreHide(true)
								setOpen(!open)
								setTimeout(() => setIgnoreHide(false), 100)
							}}
						>
							<span className={styles.inpagenav.hamburger.bar()} />
							<span className={styles.inpagenav.hamburger.bar()} />
							<span className={styles.inpagenav.hamburger.bar()} />
						</a>
						<InpagenavItems
							links={links || []}
							extraLinks={extraLinks}
							displayDark={display_dark}
						/>
					</div>
					{cta && cta.url && (
						<div>
							<div className={styles.inpagenav.button()}>
								<Theme.Button
									to={cta.url.replace('[TITLE]', encodeURIComponent(pageTitle))}
									mod={['rounded', 'white']}
								>
									<Theme.ButtonText>{cta.title}</Theme.ButtonText>
									<Theme.ButtonIcon />
								</Theme.Button>
							</div>
						</div>
					)}
				</div>
				<InpagenavItems
					links={links || []}
					extraLinks={extraLinks}
					displayDark={display_dark}
					mod="mobile"
					open={open}
				/>
			</div>
		</Block>
	)
}

export const InpagenavItems: React.FC<{
	links: Array<DPLink>
	extraLinks: Array<{
		id: string
		label: string
	}>
	displayDark?: boolean
	mod?: 'mobile'
	open?: boolean
}> = ({links, extraLinks, displayDark, mod, open}) => {
	let internalLinks = links.map((link) => {
		return {
			id: link.url,
			label: link.title
		}
	})

	return (
		<div className={styles.items.mod(mod).is({open})()}>
			{[...internalLinks, ...extraLinks].map(
				(item, i) =>
					item.id &&
					item.label && (
						<div key={i} className={styles.items.item()}>
							<Link
								to={item.id}
								className={styles.items.item.link.is({
									dark: displayDark
								})()}
							>
								{item.label}
							</Link>
						</div>
					)
			)}
		</div>
	)
}
