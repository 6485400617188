import {DPHierarchy, DPMenuTerm, DPParentTerm} from 'types/DPTypes'
import React, {useState} from 'react'

import {Icon} from 'assets/icon'
import {Link} from 'util/link'
import css from './headermobilenav.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const HeaderMobilenav: React.FC<{
	items: DPHierarchy<DPMenuTerm>
	onCloseMenu: () => void
}> = ({items, onCloseMenu}) => {
	const [active, setActive] = useState<number | null>(null)
	const item = items.find((item) => item.id === active)
	const setItem = (item: DPParentTerm<DPMenuTerm>) => {
		setActive(item?.id)
	}
	const t = useTranslation()

	return (
		<div className={styles.headermobilenav()}>
			<HeaderMobilenavContent items={items} active={item} setActive={setItem} />
			<a
				href="#"
				onClick={() => onCloseMenu()}
				className={styles.headermobilenav.close()}
				aria-label={t.header.hamburger.close_arialabel}
			>
				<span className={styles.headermobilenav.close.icon()}>
					<Icon icon="Close" />
				</span>
			</a>
		</div>
	)
}

export const HeaderMobilenavContent: React.FC<{
	items: DPHierarchy<DPMenuTerm>
	active: DPParentTerm<DPMenuTerm>
	setActive: (item: DPParentTerm<DPMenuTerm>) => void
}> = ({items, active, setActive}) => {
	if (!active) {
		return <HeaderMobilenavLinks items={items} setSubmenu={setActive} />
	}

	return <HeaderMobilenavSubmenu item={active} goBack={() => setActive(null)} />
}

export const HeaderMobilenavLinks: React.FC<{
	items: DPHierarchy<DPMenuTerm>
	setSubmenu: (HeaderLinkType) => void
}> = ({items, setSubmenu}) => {
	if (!items?.length) return null

	return (
		<div className={styles.headermobilenav_links()}>
			{items.map((item, i) => {
				if (item.children?.length) {
					return (
						<div key={i} className={styles.headermobilenav_links.item()}>
							<a
								onClick={() => setSubmenu(item)}
								className={styles.headermobilenav_links.item.link()}
							>
								<span className={styles.headermobilenav_links.item.link.text()}>
									{item.name}
								</span>
								<span className={styles.headermobilenav_links.item.link.icon()}>
									<Icon icon="ChevronRight" />
								</span>
							</a>
						</div>
					)
				}

				return (
					<div key={i} className={styles.headermobilenav_links.item()}>
						<Link
							to={item.link?.url}
							className={styles.headermobilenav_links.item.link()}
						>
							<span className={styles.headermobilenav_links.item.link.text()}>
								{item.name}
							</span>
						</Link>
					</div>
				)
			})}
		</div>
	)
}

export const HeaderMobilenavSubmenu: React.FC<{
	item: DPParentTerm<DPMenuTerm>
	goBack: () => void
}> = ({item, goBack}) => {
	const t = useTranslation()
	if (!item?.children?.length) return null

	return (
		<div className={styles.headermobilenav_submenu()}>
			<a
				className={styles.headermobilenav_submenu.back()}
				onClick={() => goBack()}
			>
				<span className={styles.headermobilenav_submenu.back.icon()}>
					<Icon icon="ArrowLeft" />
				</span>
				<span className={styles.headermobilenav_submenu.back.text()}>
					{t.header.back}
				</span>
			</a>
			<Link
				to={item.link?.url}
				className={styles.headermobilenav_submenu.link()}
			>
				<span className={styles.headermobilenav_submenu.link.text()}>
					{item.name}
				</span>
				<span className={styles.headermobilenav_submenu.link.icon()}>
					<Icon icon="ArrowRight" />
				</span>
			</Link>
			<div className={styles.headermobilenav_submenu.divider()} />
			<div className={styles.headermobilenav_submenu.items()}>
				{item.children.map((child, i) => (
					<div key={i} className={styles.headermobilenav_submenu.item()}>
						<Link
							to={child.link?.url}
							className={styles.headermobilenav_submenu.item.link()}
						>
							<span className={styles.headermobilenav_submenu.item.link.text()}>
								{child.name}
							</span>
							<span className={styles.headermobilenav_submenu.item.link.icon()}>
								<Icon icon="ArrowRight" />
							</span>
						</Link>
						<div className={styles.headermobilenav_submenu.item.description()}>
							{child.description}
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
