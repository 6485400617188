import React, {HTMLProps} from 'react'

import {ClassName} from './style'
import NextLink from 'next/link'
import {UrlObject} from 'url'
import parse from 'url-parse'
import {useRouter} from 'next/router'

export const checkIsExternal = (url) => {
	if (url.startsWith('#')) return false
	if (url.startsWith('/')) return false
	return true
}

export const checkIsFile = (url) => {
	return url.split('/').pop().includes('.')
}

export type IProps = Omit<
	| HTMLProps<HTMLAnchorElement>
	| ({as: 'button'} & HTMLProps<HTMLButtonElement>),
	'className'
> & {
	to?: string | UrlObject
	className?: ClassName | string
	forceReload?: boolean
}

export const Link = React.forwardRef(
	({children, as, to, target, forceReload, ...props}: IProps, ref: any) => {
		const router = useRouter()

		if (!to) {
			const Comp = as || 'a'
			return (
				<Comp {...(props as any)} ref={ref}>
					{children}
				</Comp>
			)
		}

		if (typeof to !== 'string') {
			return (
				<NextLink
					{...(props as any)}
					href={to}
					ref={ref}
					target={target || '_self'}
					prefetch={false}
				>
					{children}
				</NextLink>
			)
		}

		let href = to
		const isExternal = checkIsExternal(href)
		const isFile = checkIsFile(href)
		if (isFile || isExternal) {
			return (
				<a
					{...(props as any)}
					href={href}
					ref={ref}
					target={target || '_blank'}
					rel={isExternal ? 'external nofollow noopener' : null}
				>
					{children}
				</a>
			)
		}

		if (forceReload) {
			return (
				<a {...(props as any)} href={href} ref={ref} target={target || '_self'}>
					{children}
				</a>
			)
		}

		return (
			<NextLink
				{...(props as any)}
				href={href}
				ref={ref}
				target={target || '_self'}
				prefetch={false}
				onClick={(e) => {
					const parsed = parse(href)
					if (href.startsWith('internal:#') || href.startsWith('#')) {
						e.preventDefault()
						scrollToHash(href.replace('internal:', ''))
						setAnchorInHistory(href.replace('internal:', ''))
						return false
					}
					if (parsed.hash && router.asPath === parsed.pathname + parsed.query) {
						e.preventDefault()
						scrollToHash(parsed.hash)
						setAnchorInHistory(parsed.hash)
						return false
					}
					return true
				}}
			>
				{children}
			</NextLink>
		)
	}
)

export function scrollToHash(to, yOffset = -25) {
	if (!to) return

	const hash = to.substring(0, 1) === '#' ? to.substring(1) : to
	if (!hash) return

	const el: HTMLElement | null = document.getElementById(hash)
	if (!el) return
	const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset

	setTimeout(() => window.scrollTo({top: y, behavior: 'smooth'}), 0)
}

export function setAnchorInHistory(to) {
	const hash = to.substring(0, 1) === '#' ? to.substring(1) : to
	history.pushState(null, null, '#' + hash)
}
