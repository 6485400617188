import {Carousel, useCarousel} from '@codeurs/carousel'
import {
	CarouselControlsNext,
	CarouselControlsPrevious
} from 'layout/carousel/carouselcontrols'
import {DPCasesBlock, DPCasesItemBlock} from 'types/DPTypes'

import {BgFixed} from 'layout/partials/bg'
import {Block} from 'blocks/block'
import {CarouselType} from 'types/basictypes'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './cases.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useInterval} from 'util/useinterval'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const Cases: React.FC<DPCasesBlock> = ({
	_type,
	items,
	content,
	content_visible
}) => {
	const carousel = useCarousel()

	useInterval(() => {
		if (carousel.hasNext()) carousel.goNext()
		else carousel.goTo(0)
	}, 10000)

	if (!items || items.length === 0) return null
	const sliceLength = content ? 3 : items.length

	return (
		<Block type={_type} className={styles.cases()}>
			{items.slice(0, sliceLength).map((item, i) => (
				<BgFixed
					image={item.cases_background_image}
					priority
					sizes="1580px"
					className={styles.cases.bg.is({active: carousel.isActive(i)})()}
					mod="cases"
					key={i}
				/>
			))}

			<div className={styles.cases.row()}>
				<div className={styles.cases.row.carousel()}>
					<Theme.Container>
						<div className={styles.cases.carousel()}>
							<CarouselControlsPrevious
								carousel={carousel}
								className={styles.cases.carousel.prev()}
							/>
							<Carousel
								{...carousel}
								full
								className={styles.cases.carousel.slides()}
							>
								{items.slice(0, sliceLength).map((item, i) => (
									<div
										key={i}
										className={styles.cases.carousel.slide.is({
											active: carousel.isActive(i)
										})()}
									>
										<CasesItem {...item} />
									</div>
								))}
							</Carousel>
							<CarouselControlsNext
								carousel={carousel}
								className={styles.cases.carousel.next()}
							/>
						</div>
						<CasesCarouselnav
							carousel={carousel}
							items={items.slice(0, sliceLength)}
						/>
					</Theme.Container>
				</div>
				{content && content_visible && (
					<div className={styles.cases.content()}>
						<Theme.Wysiwyg mod="inherit">{content}</Theme.Wysiwyg>
					</div>
				)}
			</div>
		</Block>
	)
}

const CasesCarouselnav: React.FC<{
	carousel: CarouselType
	items?: DPCasesItemBlock[]
}> = ({carousel, items}) => {
	const t = useTranslation()
	if (items?.length < 2) return null

	return (
		<div className={styles.carouselnav()}>
			{items.map((item, i) => (
				<div
					className={styles.carouselnav.item.is({
						active: carousel.isActive(i)
					})()}
					key={i}
				>
					<button
						tabIndex={0}
						onClick={() => carousel.goTo(i)}
						aria-label={t.carousel.index_arialabel}
					>
						<span className={styles.carouselnav.item.line()} />
						<span className={styles.carouselnav.item.title()}>
							{item.title}
						</span>
					</button>
				</div>
			))}
		</div>
	)
}

const CasesItem: React.FC<DPCasesItemBlock> = ({title, description, link}) => {
	return (
		<div className={styles.item()}>
			{title && (
				<div className={styles.item.title()}>
					<Theme.H2>{title}</Theme.H2>
				</div>
			)}
			{description && (
				<p className={styles.item.description()}>{description}</p>
			)}
			{link && link.url && (
				<Theme.Button
					to={link.url}
					className={styles.item.link()}
					mod="rounded"
				>
					<Theme.ButtonText>{link.title}</Theme.ButtonText>
					<Theme.ButtonIcon />
				</Theme.Button>
			)}
		</div>
	)
}
