import {Column, Grid} from 'layout/grid'
import {DPArticle, DPPress, DPVideoPage} from 'types/DPTypes'

import {Imagepreview} from 'layout/preview/imagepreview'
import {Link} from 'util/link'
import {PreviewType} from 'layout/preview/previewtype'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './magazine.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useDpDateToPrettyDate} from 'util/dates'

const styles = fromModule(css)

export const Magazine: React.FC<{children: React.ReactNode}> = ({children}) => {
	return <div className={styles.magazine()}>{children}</div>
}

export const MagazineItems: React.FC<{
	items: Array<DPArticle | DPPress | DPVideoPage>
}> = (data) => {
	const {items} = data

	return (
		<div className={styles.items()}>
			<Theme.Container>
				<Grid columns={1} xs={2} s={2} m={3} l={3} gap={[30, 65]}>
					{items.map((item, i) => (
						<Column key={`${i}_${item.node_id}`}>
							<Imagepreview {...item} />
						</Column>
					))}
				</Grid>
			</Theme.Container>
		</div>
	)
}

export const MagazineTextualItems: React.FC<{
	items: Array<PreviewType>
}> = (data) => {
	const {items} = data
	const {printDate} = useDpDateToPrettyDate()

	return (
		<div className={styles.textual()}>
			{items.map((result, i) => (
				<Link key={i} to={result.url} className={styles.textual()}>
					<Theme.Container>
						<p className={styles.textual.date()}>{printDate(result.date)}</p>
						<div className={styles.textual.title()}>
							<Theme.H4>{result.title}</Theme.H4>
						</div>
						{result.description && (
							<div className={styles.textual.description()}>
								<Theme.Wysiwyg>{result.description}</Theme.Wysiwyg>
							</div>
						)}
					</Theme.Container>
				</Link>
			))}
		</div>
	)
}
