import { Block } from 'blocks/block'
import React from 'react'
import { DPHeroBlock } from 'types/DPTypes'
import { Hero } from './hero'

/**
 * I created HeroBlock to solve circular dependency issues.
 * Never use this Component directly, use Hero instead.
 */
export const HeroBlock: React.FC<DPHeroBlock> = (data) => {

	return <Block type="hero">
		<Hero {...data} />
	</Block>
}