import {DPLogosBlock, DPLogosItemBlock, DPSponsorsBlock} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {Image} from 'layout/partials/image'
import {Link} from 'util/link'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './sponsors.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Sponsors: React.FC<DPSponsorsBlock> = ({_type, title, logos}) => {
	if (!logos || logos.length === 0) return null

	return (
		<Block type={_type} className={styles.sponsors()}>
			<Theme.Container>
				{title && (
					<Theme.H2 mod={'blueline'} className={styles.sponsors.title()}>
						{title}
					</Theme.H2>
				)}
				{logos.map((logoList, i) => (
					<SponsorsLogos {...logoList} key={i} />
				))}
			</Theme.Container>
		</Block>
	)
}

const SponsorsLogos: React.FC<DPLogosBlock> = ({title, items}) => {
	if (!items || items.length === 0) return null

	return (
		<div className={styles.logos()}>
			{title && (
				<Theme.H4 mod={'blueline'} className={styles.logos.title()}>
					{title}
				</Theme.H4>
			)}
			<div className={styles.logos.items()}>
				{items.map((item, i) => (
					<div key={i} className={styles.logos.items.item()}>
						<Logo {...item} />
					</div>
				))}
			</div>
		</div>
	)
}

const Logo: React.FC<DPLogosItemBlock> = ({image, link}) => {
	if (!image) return null
	const imgWidth = 150 / (image?.height / image?.width)

	return (
		<div className={styles.logo()} style={{width: imgWidth}}>
			{!!link && (
				<Link to={link}>
					<Image {...image} height={150} width={imgWidth} sizes="175px" />
				</Link>
			)}
			{!link && (
				<Image {...image} height={150} width={imgWidth} sizes="175px" />
			)}
		</div>
	)
}
