import {facebookLink, linkedInLink, twitterLink} from 'util/share'

import {BlockFC} from 'blocks/block'
import {DPShareBlock} from 'types/DPTypes'
import {Icon} from 'assets/icon'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './share.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useRouter} from 'next/router'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const Share: BlockFC<DPShareBlock> = () => {
	const links = ['facebook', 'twitter', 'linkedin']
	const {node} = useApp()
	const t = useTranslation()

	if (!links || links.length == 0) return null

	return (
		<div className={styles.share()}>
			<Theme.Container>
				<p className={styles.share.text()}>{t.share}</p>
				<div className={styles.share.icons()}>
					{links.map((link, i) => (
						<Sharelink
							key={i}
							type={link}
							description={node?.meta?.description}
							title={node?.title}
						/>
					))}
				</div>
			</Theme.Container>
		</div>
	)
}

export const Sharelink: React.FC<{
	type: string
	description?: string
	title?: string
}> = (data) => {
	const {type} = data
	const router = useRouter()
	const t = useTranslation()
	return (
		<a
			{...sharePlatform(type, router.asPath)}
			target="_blank"
			className={styles.link.mod(type)()}
			title={t.social[type]}
			aria-label={t.share + ' ' + t.social[type]}
		>
			<span className={styles.link.icon()}>
				<SociallinkIcon type={type} />
			</span>
		</a>
	)
}

const SociallinkIcon: React.FC<{type: string}> = ({type}) => {
	switch (type) {
		case 'facebook':
			return <Icon icon="Facebook" />
		case 'linkedin':
			return <Icon icon="LinkedIn" />
		case 'twitter':
			return <Icon icon="Twitter" />
		default:
			return null
	}
}

function sharePlatform(type, href) {
	if (!type) return null

	const fullHref = process.env.NEXT_PUBLIC_WEBSITE_URL + href

	switch (type) {
		case 'facebook':
			return facebookLink(fullHref)
		case 'twitter':
			return twitterLink('', fullHref)
		case 'linkedin':
			return linkedInLink(fullHref)
		default:
			return null
	}
}
