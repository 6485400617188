import {DPNode} from 'types/DPTypes'
import {Image} from 'layout/partials/image'
import {Link} from 'util/link'
import {Mod} from 'util/style'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './imagepreview.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useDpDateToPrettyDate} from 'util/dates'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const Imagepreview: React.FC<
	DPNode & {mod?: Mod<'inherit'>; tabIndex?: number}
> = (data) => {
	const {_type, url, title, description, date, image, mod, tabIndex} = data

	const {printDate} = useDpDateToPrettyDate()
	const t = useTranslation()
	const label = t.getNodeLabel(data)

	return (
		<div className={styles.imagepreview.mod(mod)()}>
			<Link
				to={url}
				tabIndex={tabIndex}
				className={styles.imagepreview.imgcontainer()}
			>
				{image?.src && <Image {...image} fill sizes="460px" />}
				<div className={styles.imagepreview.imgcontainer.overlay()} />
				{_type === 'video_page' && (
					<span className={styles.imagepreview.imgcontainer.play()}></span>
				)}
				{_type && (
					<p className={styles.imagepreview.imgcontainer.type()}>
						{switchPagetypes(_type, t, label)}
					</p>
				)}
				{_type === 'press' && date && (
					<p className={styles.imagepreview.imgcontainer.date()}>
						{printDate(date)}
					</p>
				)}
				{/* {label && (
					<p className={styles.imagepreview.imgcontainer.label()}>{label}</p>
				)} */}
				{/* {tags && tags.length !== 0 && (
					<div className={styles.imagepreview.imgcontainer.tags()}>
						<Tags tags={tags} tabIndex={tabIndex} />
					</div>
				)} */}
			</Link>
			<div className={styles.imagepreview.content()}>
				<Theme.H4 className={styles.imagepreview.content.title()} mod="light">
					<Link to={url} tabIndex={tabIndex}>
						{title}
					</Link>
				</Theme.H4>
				{description && (
					<Theme.Wysiwyg className={styles.imagepreview.content.description()}>
						{description}
					</Theme.Wysiwyg>
				)}
			</div>
		</div>
	)
}

function switchPagetypes(type: DPNode['_type'], t, label?: string) {
	switch (type) {
		case 'article':
			return label
		case 'press':
		case 'video_page':
			return t.readingroom.types[type]
		default:
			return null
	}
}
