import {CarouselType} from 'types/basictypes'
import {Icon} from 'assets/icon'
import React from 'react'
import css from './carouselcontrols.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const CarouselControls: React.FC<{
	carousel: CarouselType
}> = (data) => {
	const {carousel} = data
	if (!carousel || carousel.total <= 1) return null

	return (
		<div className={styles.carouselcontrols()}>
			<CarouselControlsPrevious carousel={carousel} />
			<CarouselControlsNext carousel={carousel} />
		</div>
	)
}

export const CarouselControlsPrevious: React.FC<{
	carousel: CarouselType
	className?: string
}> = (data) => {
	const {carousel, className} = data
	const t = useTranslation()
	if (!carousel || carousel.total <= 1) return null

	return (
		<button
			onClick={() =>
				carousel.hasPrevious()
					? carousel.goPrevious()
					: carousel.goTo(carousel.total - 1)
			}
			aria-label={t.carousel.prev_arialabel}
			className={styles.previous.with(className)()}
		>
			<Icon icon="ChevronLeft" />
		</button>
	)
}

export const CarouselControlsNext: React.FC<{
	carousel: CarouselType
	className?: string
}> = (data) => {
	const {carousel, className} = data
	const t = useTranslation()
	if (!carousel || carousel.total <= 1) return null

	return (
		<button
			onClick={() =>
				carousel.hasNext() ? carousel.goNext() : carousel.goTo(0)
			}
			aria-label={t.carousel.next_arialabel}
			className={styles.next.with(className)()}
		>
			<Icon icon="ChevronRight" />
		</button>
	)
}
