import {Carousel, useCarousel} from '@codeurs/carousel'
import {DPProduct, DPProductspreviewBlock} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {CarouselNav} from 'layout/carousel/carouselnav'
import {Image} from 'layout/partials/image'
import {Link} from 'util/link'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './productspreview.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useMediaQuery} from 'util/mediaquery'

const styles = fromModule(css)

export const ProductsPreview: React.FC<DPProductspreviewBlock> = (data) => {
	const {_type, items, cta} = data
	if (!items || items.length === 0) return null

	return (
		<Block type={_type} className={styles.productspreview()}>
			<Theme.Container>
				<ProductsPreviewItems items={items} />
				{cta && cta.url && (
					<div className={styles.productspreview.button()}>
						<Theme.Button to={cta.url} mod="rounded">
							<Theme.ButtonText>{cta.title}</Theme.ButtonText>
							<Theme.ButtonIcon />
						</Theme.Button>
					</div>
				)}
			</Theme.Container>
		</Block>
	)
}

const ProductsPreviewItems: React.FC<{
	items: Array<DPProduct>
}> = ({items}) => {
	const tablet = useMediaQuery('(min-width: 600px)')
	const ipadPort = useMediaQuery('(min-width: 768px)')
	const desktop = useMediaQuery('(min-width: 1160px)')

	switch (true) {
		case tablet && items.length <= 2:
		case ipadPort && items.length <= 3:
		case desktop && items.length <= 5:
			return (
				<div className={styles.items()}>
					{items.map((item, i) => (
						<div key={i} className={styles.items.item()}>
							<ProductsPreviewItem {...item} />
						</div>
					))}
				</div>
			)
		default:
			return <ProductsPreviewCarousel items={items} />
	}
}

const ProductsPreviewCarousel: React.FC<{
	items: Array<DPProduct>
}> = ({items}) => {
	const carousel = useCarousel()

	return (
		<div className={styles.carousel()}>
			<Carousel {...carousel}>
				{items.map((item, i) => (
					<div
						key={i}
						className={styles.carousel.item.is({
							active: carousel.isActive(i)
						})()}
					>
						<ProductsPreviewItem {...item} />
					</div>
				))}
			</Carousel>
			<CarouselNav carousel={carousel} className={styles.carousel.nav()} />
		</div>
	)
}

const ProductsPreviewItem: React.FC<DPProduct & {mod?: 'carousel'}> = ({
	url,
	image,
	mod
}) => (
	<Link to={url} className={styles.item.mod(mod)()}>
		<Image
			{...image}
			sizes="(max-width: 600px) 100vw, (max-width: 768px) 33vw, 20vw"
		/>
	</Link>
)
