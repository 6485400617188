import {
	Contentsidebar,
	ContentsidebarContent,
	ContentsidebarSidebar
} from 'layout/sidebar/contentsidebar'
import {DPArticleNode, DPRelatedBlock} from 'types/DPTypes'
import React, {useEffect} from 'react'

import {BgFixed} from 'layout/partials/bg'
import {Blocks} from 'blocks/blocks'
import {Contact} from 'blocks/contact/contact'
import {Footer} from 'layout/partials/footer/footer'
import {Icon} from 'assets/icon'
import {Link} from 'util/link'
import {Mousescroll} from 'layout/mousescroll'
import {Paragraph} from 'blocks/paragraph'
import {Related} from 'blocks/related/related'
import {Share} from 'blocks/share/share'
import {Sidebar} from 'layout/sidebar/sidebar'
import {Theme} from 'layout/theme'
import {countWords} from 'util/countWords'
import css from './article.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useLinks} from 'util/links'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const Article: React.FC<DPArticleNode> = (node) => {
	const {general} = useApp()
	const t = useTranslation()

	useEffect(() => {
		// @ts-ignore
		if (window['dataLayer']) {
			// @ts-ignore
			dataLayer.push({
				Author: (node?.authors || []).map((author) => {
					if (author._type === 'article_author') return author.name
					if (author._type === 'article_existing_author')
						return author.existing_author_author?.name
				}),
				ContentType: 'artikel',
				WordCount: countWords(node.blocks),
				ContentTags: node.tags.map((tag) => tag.name),
				PublishDate: node.date
			})
		}
	}, [node.authors, node.blocks, node.date, node.tags])

	const relatedBlock = node.blocks.find(
		(bl) => bl._type === 'related'
	) as DPRelatedBlock

	return (
		<div className={styles.article()}>
			<ArticleHero {...node} />
			<span id="scroll" />
			<Theme.OuterContainer>
				<Contentsidebar className={styles.article.contentsidebar()}>
					<ContentsidebarContent>
						<Blocks fullwidth={true} blocks={node.blocks}>
							{node.blocks
								.filter((bl) => bl._type !== 'related')
								.map((block) => (
									<Paragraph {...block} key={block.id} />
								))}
						</Blocks>
					</ContentsidebarContent>
					<ContentsidebarSidebar>
						<Sidebar
							authors={(node.authors || []).map((auth) => {
								switch (auth._type) {
									case 'article_author':
										return {
											name: auth.name,
											description: auth.bio,
											function: auth.function,
											image: auth.image
										}
									case 'article_existing_author':
										if (!auth.existing_author_author) return null
										return {
											name: auth.existing_author_author.name,
											description: auth.existing_author_author.avatar_bio,
											function: auth.existing_author_author.author_function,
											image: auth.existing_author_author.author_avatar_image
										}
								}
							})}
							tags={node.tags}
							date={node.date}
							more={node.more}
						/>
					</ContentsidebarSidebar>
				</Contentsidebar>
				<div className={styles.article.bottom()}>
					<Share />
					{process.env.NEXT_PUBLIC_PROJECT_KEY === 'corporate' && (
						<Contact
							_type="contact"
							id={null}
							avatar_image={null}
							email={null}
							link={{
								title: 'Contact us',
								url: '/en/about-us/imec-magazine/contact-form-imec-magazine'
							}}
							recipient_name={null}
							text={null}
						/>
					)}
					{!relatedBlock && (
						<Related
							_type="related"
							id={0}
							title={t.article.readmore}
							items={node.related}
							link_title={null}
						/>
					)}
					{relatedBlock && <Related {...relatedBlock} />}
				</div>
			</Theme.OuterContainer>
			<Footer {...general.footer} />
		</div>
	)
}

export const ArticleHero: React.FC<DPArticleNode> = (node) => {
	const t = useTranslation()

	return (
		<div className={styles.hero.mod({image: !!node.background_image})()}>
			{node.background_image && (
				<BgFixed
					image={node.background_image}
					priority
					sizes="(max-width: 1580px) 100vw, 1580px"
				/>
			)}
			<ArticleHeroTop />
			<Theme.OuterContainer>
				<Theme.Container>
					<div className={styles.hero.row()}>
						<div className={styles.hero.content()}>
							{node.label && (
								<p className={styles.hero.content.subtitle()}>
									{node.label.name}
								</p>
							)}
							<Theme.H1 className={styles.hero.content.title()}>
								{node.title}
							</Theme.H1>
							{node.description && (
								<div className={styles.hero.content.description()}>
									<Theme.Wysiwyg>{node.description}</Theme.Wysiwyg>
								</div>
							)}
						</div>
						{node.summary && (
							<div className={styles.hero.summary()}>
								<span className={styles.hero.summary.title()}>
									{t.article.short}
								</span>
								<Theme.TrustedText>{node.summary}</Theme.TrustedText>
							</div>
						)}
					</div>
				</Theme.Container>
			</Theme.OuterContainer>
			<div className={styles.article.scroll()}>
				<Theme.Container>
					<Mousescroll id="#scroll" />
				</Theme.Container>
			</div>
		</div>
	)
}

export const ArticleHeroTop: React.FC = () => {
	const {home, readingroom} = useLinks()
	const t = useTranslation()

	return (
		<div className={styles.herotop()}>
			<div className={styles.herotop.left()}>
				<Link className={styles.herotop.left.cta()} to={readingroom.url}>
					<div className={styles.herotop.left.cta.circle()}>
						<span className={styles.herotop.left.cta.circle.icon()}>
							<Icon icon="ArrowLeft" />
						</span>
					</div>
					<p className={styles.herotop.left.cta.text()}>{t.article.back}</p>
				</Link>
			</div>
			<div className={styles.herotop.center()}>
				<Link className={styles.herotop.center.logo()} to={home.url}>
					<img
						className={styles.herotop.center.logo.img()}
						src="/assets/img/imec-logo-white.svg"
						alt="imec logo"
					/>
				</Link>
			</div>
			<div className={styles.herotop.right()} />
		</div>
	)
}
