import {
	DPArticle,
	DPDossier,
	DPPress,
	DPReadingRoomBlock,
	DPVideoPage
} from 'types/DPTypes'
import {
	Magazine,
	MagazineItems,
	MagazineTextualItems
} from 'blocks/readingroom/components/magazine'
import {SearchParams, useSearchParams} from 'util/useSearchParams'
import {
	SearchresultsEmpty,
	SearchresultsLoader,
	SearchresultsMore
} from 'layout/search/searchresults'

import {Highlighted} from 'blocks/readingroom/components/highlighted'
import {PreviewType} from 'layout/preview/previewtype'
import {READINGROOM_SEARCH_URL} from 'util/urls'
import React from 'react'
import {ReadingroomSearch} from 'blocks/readingroom/components/readingroomsearch'
import css from './readingroom.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useFetcher} from 'util/fetcher'
import {useLinks} from 'util/links'
import {useRouter} from 'next/router'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)
const PAGE_AMOUNT = 9

export type ReadingroomSearchParams = {
	page: number
	query: string
	partner_related: boolean
	type: string
	tags: string[]
}

export type ReadingroomType = {
	categories: Array<string>
	highlighted: Array<PreviewType>
}

export const useReadingroomSearchParams = () => {
	const router = useRouter()
	const result = useSearchParams()
	const {readingroom} = useLinks()

	const isDynamic =
		Object.keys(router.query).filter((key) => !['slug', 'page'].includes(key))
			.length > 0

	const showClearBtn =
		Object.keys(router.query).filter(
			(key) => !['slug', 'page', 'type'].includes(key)
		).length > 0

	return {
		...result,
		isDynamic,
		showClearBtn,
		createUrlObject: (params: Partial<SearchParams>) => {
			const urlObject = result.createUrlObject(params)
			return {
				...urlObject,
				query: {
					...urlObject.query,
					slug: readingroom.url.split('/').slice(1)
				}
			}
		}
	}
}

export const Readingroom: React.FC<DPReadingRoomBlock> = ({
	categories,
	items,
	reading_dossiers
}) => {
	const {isDynamic} = useReadingroomSearchParams()

	return (
		<div className={styles.readingroom()}>
			<ReadingroomSearch categories={categories} popularTags={[]} />
			<ReadingroomTabs />
			{isDynamic ? (
				<ReadingroomDynamic />
			) : (
				<ReadingroomStatic items={items} dossiers={reading_dossiers || []} />
			)}
		</div>
	)
}

const ReadingroomTabs: React.FC<{}> = () => {
	const {updateParams, type} = useReadingroomSearchParams()
	const t = useTranslation()

	return (
		<div className={styles.tabs()}>
			<div className={styles.tabs.items()}>
				<div
					className={styles.tabs.items.item.is({active: !type})()}
					onClick={() => updateParams({type: null})}
				>
					{t.readingroom.tabs.all}
				</div>
				{['press', 'article', 'video_page'].map((itemType) => (
					<div
						key={`tab_${itemType}`}
						className={styles.tabs.items.item.is({active: type === itemType})()}
						onClick={() => updateParams({type: itemType})}
					>
						{t.readingroom.tabs[itemType]}
					</div>
				))}
			</div>
		</div>
	)
}

export const ReadingroomStatic: React.FC<{
	items: Array<DPArticle | DPPress | DPVideoPage>
	dossiers: Array<DPDossier>
}> = ({items, dossiers}) => {
	const {page, setParams} = useReadingroomSearchParams()

	return (
		<>
			<Magazine>
				<MagazineItems items={items.slice(0, 3)} />
				<Highlighted dossier={dossiers[0]} />
				<MagazineItems items={items.slice(3, PAGE_AMOUNT)} />
				{page === 0 && items.length > PAGE_AMOUNT && (
					<SearchresultsMore onClick={() => setParams({page: 1})} />
				)}
				{page === 0 && items.length <= PAGE_AMOUNT && dossiers.length > 1 && (
					<>
						{dossiers.slice(1).map((d, i) => (
							<Highlighted dossier={d} key={'dossier' + i} />
						))}
					</>
				)}
			</Magazine>
			{page >= 1 && (
				<ReadingroomDynamic
					skip={items.slice(0, PAGE_AMOUNT)}
					dossiers={dossiers.slice(1)}
				/>
			)}
		</>
	)
}

export const ReadingroomDynamic: React.FC<{
	skip?: Array<DPArticle | DPPress | DPVideoPage>
	dossiers?: Array<DPDossier>
}> = ({skip, dossiers = []}) => {
	const {page, label, tags, type, query, partner_related, updateParams} =
		useReadingroomSearchParams()

	const url = READINGROOM_SEARCH_URL({
		page,
		label,
		query,
		tags,
		partner_related,
		type,
		skip
	})
	const {data, isLoading} = useFetcher(url)

	if (!data) return <SearchresultsLoader />
	if (!data.items.length) return <SearchresultsEmpty />

	const visiblePage = isLoading ? page - 1 : page
	const allItems = data?.items
	const nrOfItems = (visiblePage + 1) * PAGE_AMOUNT
	const items = allItems.slice(0, nrOfItems)
	const showMore = allItems.length > nrOfItems

	if (partner_related) {
		return (
			<>
				{dossiers.length ? (
					<>
						{dossiers
							.slice(0, Math.ceil(items.length / 6))
							.map((dossier, index) => {
								return (
									<>
										<Highlighted dossier={dossier} />
										<MagazineTextualItems
											items={items.slice(index * 6, (index + 1) * 6)}
										/>
									</>
								)
							})}
						<MagazineTextualItems items={items.slice(dossiers.length * 6)} />
					</>
				) : (
					<MagazineTextualItems items={items} />
				)}
				{isLoading && <SearchresultsLoader />}
				{showMore && !isLoading && (
					<SearchresultsMore onClick={() => updateParams({page: page + 1})} />
				)}
			</>
		)
	}

	return (
		<Magazine>
			{dossiers.length ? (
				<>
					{dossiers
						.slice(0, Math.ceil(items.length / 6))
						.map((dossier, index) => {
							return (
								<>
									<Highlighted dossier={dossier} />
									<MagazineItems
										items={items.slice(index * 6, (index + 1) * 6)}
									/>
								</>
							)
						})}
					<MagazineItems items={items.slice(dossiers.length * 6)} />
				</>
			) : (
				<MagazineItems items={items} />
			)}
			{isLoading && <SearchresultsLoader />}
			{showMore && !isLoading && (
				<SearchresultsMore onClick={() => updateParams({page: page + 1})} />
			)}
		</Magazine>
	)
}
