import {Column, Grid} from 'layout/grid'
import {
	DPServicesBlock,
	DPServicesItemBlock,
	DPServicesLinkBlock,
	DPWysiwyg
} from 'types/DPTypes'

import {BgFixed} from 'layout/partials/bg'
import {Block} from 'blocks/block'
import {Cardpreview} from 'layout/preview/cardpreview'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './services.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Services: React.FC<DPServicesBlock> = (data) => {
	const {_type, background_image, text, display_right = false, items} = data
	const singleItem = items && items.length <= 1

	return (
		<Block
			type={_type}
			className={styles.services.mod({bg: background_image})()}
		>
			{background_image && (
				<BgFixed
					image={background_image}
					className={styles.services.bg_container()}
				/>
			)}
			<Theme.Container>
				<Grid
					columns={1}
					xs={1}
					s={1}
					m={singleItem ? 2 : 1}
					l={singleItem ? 2 : 3}
					gap={[30, 50]}
				>
					<Column m={{order: singleItem && display_right ? 1 : 0}}>
						{text && <ServicesContent text={text} />}
					</Column>
					<Column span={singleItem ? 1 : 2}>
						<div
							className={background_image && styles.services.itemscontainer()}
						>
							<Grid
								columns={1}
								xs={1}
								s={singleItem ? 1 : 2}
								m={singleItem ? 1 : 2}
								l={singleItem ? 6 : 2}
							>
								{(items || []).map((item) => (
									<Column
										key={item.id}
										span={singleItem ? 4 : 1}
										offset={singleItem ? 1 : 0}
									>
										<ServicesItem item={item} />
									</Column>
								))}
							</Grid>
						</div>
					</Column>
				</Grid>
			</Theme.Container>
		</Block>
	)
}

export const ServicesItem: React.FC<{
	item: DPServicesItemBlock | DPServicesLinkBlock
}> = ({item}) => {
	if (item._type === 'services_link') {
		return (
			<Cardpreview
				url={item.link?.url}
				image={item.link?.image}
				text={`
				<h4>${item.link?.title}</h4>
				<p>${item.link?.description}</p>
			`}
				display_background_dark={true}
			/>
		)
	}

	return (
		<Cardpreview
			url={item.link?.url}
			image={item.image}
			text={item.text}
			display_background_dark={true}
		/>
	)
}

export const ServicesContent: React.FC<{
	text: DPWysiwyg
}> = ({text}) => {
	return (
		<div className={styles.content()}>
			{text && <Theme.Wysiwyg mod="inherit">{text}</Theme.Wysiwyg>}
		</div>
	)
}
