import {Column, Grid} from 'layout/grid'
import {DPArticle, DPDossier, DPNode, DPPress, DPVideoPage} from 'types/DPTypes'

import {BgFixed} from 'layout/partials/bg'
import {CookieMissing} from 'layout/cookies/cookiemissing'
import {Imagepreview} from 'layout/preview/imagepreview'
import {Link} from 'util/link'
import React from 'react'
import {Theme} from 'layout/theme'
import {Trusted} from 'util/trusted'
import css from './highlighted.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useDpDateToPrettyDate} from 'util/dates'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const Highlighted: React.FC<{
	dossier: DPDossier
}> = ({dossier}) => {
	if (!dossier) return null
	const {url, title, articles} = dossier
	if (articles?.length === 0) return null

	return (
		<div className={styles.highlighted()}>
			<Theme.Container>
				<Theme.H1
					as="h2"
					className={styles.highlighted.title()}
					mod={['arrow', 'white']}
				>
					<Link to={url}>{`Dossier: ${title}`}</Link>
				</Theme.H1>
				<div className={styles.highlighted.first()}>
					<Grid columns={1} m={2} l={12}>
						<Column l={8}>
							{articles[0]?._type === 'video_page' ? (
								<HighlightedVideo {...articles[0]} />
							) : (
								<HighlightedImage {...articles[0]} />
							)}
						</Column>
						<Column l={4}>
							<HighlightedContent highlighted={articles[0]} />
						</Column>
					</Grid>
				</div>
				<div className={styles.highlighted.items()}>
					<Grid columns={1} xs={2} s={2} m={3} l={3} gap={[30, 65]}>
						{articles.slice(1, articles.length).map((item, i) => (
							<Column key={`${i}_${item.node_id}`}>
								<Imagepreview {...item} mod="inherit" />
							</Column>
						))}
					</Grid>
				</div>
			</Theme.Container>
		</div>
	)
}

export const HighlightedImage: React.FC<DPArticle | DPPress> = ({
	url,
	background_image
}) => {
	const t = useTranslation()

	return (
		<Link to={url} className={styles.image()}>
			<BgFixed
				image={background_image}
				hideOverlay={true}
				className={styles.image.bg()}
			/>
		</Link>
	)
}

export const HighlightedVideo: React.FC<DPVideoPage> = ({embed_code}) => {
	if (!embed_code) return null
	return (
		<div className={styles.media()}>
			<CookieMissing object={embed_code}>
				<Trusted>{embed_code}</Trusted>
			</CookieMissing>
		</div>
	)
}

export const HighlightedContent: React.FC<{
	highlighted: DPArticle | DPPress | DPVideoPage
}> = (data) => {
	const t = useTranslation()
	const {printDate} = useDpDateToPrettyDate()
	const {_type, url, tags, title, date, description} = data.highlighted

	return (
		<div className={styles.content()}>
			{/* Deprecated: Not applicable from redesign-2022. */}
			{/* {tags.length > 0 && (
				<div className={styles.content.tags()}>
					<Tags tags={tags} />
				</div>
			)} */}
			{_type && (
				<p className={styles.content.type()}>{switchPagetypes(_type, t)}</p>
			)}
			<Theme.H2 as="h3" className={styles.content.title()}>
				<Link to={url}>{title}</Link>
			</Theme.H2>
			{date && <p className={styles.content.date()}>{printDate(date)}</p>}
			{description && (
				<div className={styles.content.text()}>
					<Theme.Wysiwyg>{description}</Theme.Wysiwyg>
				</div>
			)}
		</div>
	)
}

function switchPagetypes(type: DPNode['_type'], t) {
	switch (type) {
		case 'article':
			return t.readingroom.types.article
		case 'press':
			return t.readingroom.types.press
		case 'video_page':
			return t.readingroom.types.video
		default:
			return null
	}
}
