import { Theme } from 'layout/theme'
import React from 'react'
import { WysiwygType } from 'types/basictypes'
import { fromModule } from 'util/styler/Styler'
import css from './error.module.scss'


const styles = fromModule(css)

export type ErrorType = {
  description: WysiwygType
}

export const Error: React.FC<ErrorType> = data => {
  const { description } = data

  return (
    <div className={styles.error()}>
      <Theme.Container>
        <Theme.TrustedText>
          {description}
        </Theme.TrustedText>
      </Theme.Container>
    </div>
  )
}
