import {Carousel, useCarousel} from '@codeurs/carousel'
import {DPNewsBlock, DPPress} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {CarouselControls} from 'layout/carousel/carouselcontrols'
import {CarouselType} from 'types/basictypes'
import {Icon} from 'assets/icon'
import {Imagepreview} from 'layout/preview/imagepreview'
import {Link} from 'util/link'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './news.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useLinks} from 'util/links'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const News: React.FC<DPNewsBlock> = (data) => {
	const {_type, title, items} = data
	const carousel = useCarousel()
	const {news} = useLinks()

	const visibleItems = items.slice(0, 12)

	return (
		<Block type={_type} className={styles.news()}>
			<Theme.Container>
				<div className={styles.news.top()}>
					<div className={styles.news.top.title()}>
						<Link to={news.url}>
							<Theme.H2 mod={['blueline', 'arrow']}>
								{title || news.title}
							</Theme.H2>
						</Link>
					</div>
					{carousel && carousel.total > 1 && (
						<div className={styles.news.top.nav()}>
							<CarouselControls carousel={carousel} />
						</div>
					)}
				</div>
			</Theme.Container>
			<NewsContent carousel={carousel} items={visibleItems} />
		</Block>
	)
}

export const NewsContent: React.FC<{
	carousel: CarouselType
	items: Array<DPPress>
}> = (data) => {
	const {carousel, items} = data
	if (!items || items.length === 0) return null

	return (
		<div className={styles.content()}>
			<Theme.Container>
				<div className={styles.content.items()}>
					<Carousel {...carousel} full>
						{items.map((item, i) => (
							<div
								key={i}
								className={styles.content.items.item.is({
									active: carousel.isActive(i)
								})()}
							>
								<Imagepreview {...item} />
							</div>
						))}
						<div className={styles.content.items.item()}>
							<NewsContentExtra />
						</div>
					</Carousel>
				</div>
			</Theme.Container>
		</div>
	)
}

export const NewsContentExtra: React.FC = () => {
	const {press} = useLinks()
	const t = useTranslation()

	return (
		<Link to={press.url} className={styles.contentextra()}>
			<div className={styles.contentextra.content()}>
				<div className={styles.contentextra.content.icon()}>
					<Icon icon="Book" />
				</div>
				<Theme.H5 className={styles.contentextra.content.title()} mod="light">
					{t.article.readmore}
				</Theme.H5>
			</div>
		</Link>
	)
}
