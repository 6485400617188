export * as Academic from './academic.svg'
export * as ArrowDown from './arrow-down.svg'
export * as ArrowLeft from './arrow-left.svg'
export * as ArrowRight from './arrow-right.svg'
export * as Bell from './bell.svg'
export * as Book from './book.svg'
export * as Bookmark from './bookmark.svg'
export * as Career from './career.svg'
export * as ChevronDown from './chevron-down.svg'
export * as ChevronLeft from './chevron-left.svg'
export * as ChevronRight from './chevron-right.svg'
export * as Close from './close.svg'
export * as Download from './download.svg'
export * as Envelope from './envelope.svg'
export * as Facebook from './facebook.svg'
export * as File from './file.svg'
export * as Flickr from './flickr.svg'
export * as Fullscreen from './fullscreen.svg'
export * as Home from './home.svg'
export * as Instagram from './instagram.svg'
export * as LinkedIn from './linkedin.svg'
export * as Location from './location.svg'
export * as Minus from './minus.svg'
export * as Mobile from './mobile.svg'
export * as Phone from './phone.svg'
export * as Plus from './plus.svg'
export * as Search from './search.svg'
export * as Settings from './settings.svg'
export * as Swivle from './swivle.svg'
export * as Twitter from './twitter.svg'
export * as Vimeo from './vimeo.svg'
export * as YouTube from './youtube.svg'
