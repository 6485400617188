import {Column, Grid} from 'layout/grid'

import {Block} from 'blocks/block'
import {DPContactBlock} from 'types/DPTypes'
import {Icon} from 'assets/icon'
import {Image} from 'layout/partials/image'
import {Link} from 'util/link'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './contact.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'blocks/anchor/anchor'
import {useLinks} from 'util/links'
import {useRouter} from 'next/router'

const styles = fromModule(css)

export const Contact: React.FC<DPContactBlock> = (data) => {
	const {_type, text, avatar_image, link, id: paragraphID} = data
	const {contact} = useLinks()
	const id = useIDFromAnchorBlock(data)
	const router = useRouter()

	let contactUrl = link?.url || contact.url
	if (contactUrl.indexOf('?') === -1) {
		const originalUrl = encodeURIComponent(router.asPath)
		contactUrl = `${contactUrl}?origin_url=${originalUrl}&contactblock=${paragraphID}#contact`
	}

	return (
		<Block type={_type} anchorID={id} className={styles.contact()}>
			<Theme.Container>
				<Grid columns={1} l={12} align="middle">
					<Column l={8}>
						<Grid columns={1} s={12} m={12} l={12} align="middle">
							{avatar_image && (
								<Column span={4}>
									<Image
										{...avatar_image}
										sizes="(max-width: 600px) 100vw, 460px"
									/>
								</Column>
							)}
							<Column span={avatar_image ? 8 : 12}>
								{text && (
									<div className={styles.contact.content()}>
										<Theme.Wysiwyg>{text}</Theme.Wysiwyg>
									</div>
								)}
							</Column>
						</Grid>
					</Column>
					<Column l={4}>
						<Link className={styles.contact.cta()} to={contactUrl}>
							<p className={styles.contact.cta.text()}>
								{(link && link.title) || contact.title}
							</p>
							<div className={styles.contact.cta.circle()}>
								<span className={styles.contact.cta.circle.icon()}>
									<Icon icon="ArrowRight" />
								</span>
							</div>
						</Link>
					</Column>
				</Grid>
			</Theme.Container>
		</Block>
	)
}
