import {DPTagTerm} from 'types/DPTypes'
import {PING_TAG} from 'util/urls'
import unfetch from 'unfetch'
import {useRouter} from 'next/router'

export type SearchParams = {
	terms: number[]
	tags: string[]
	filters: string[]
	page: number
	type: string
	label: string
	query: string
	partner_related: boolean
}

export const useStringUrlParams = () => {
	const router = useRouter()
	return router.query as Record<string, string>
}

export const useSearchParams = () => {
	const router = useRouter()
	const query = router.query
	const result: SearchParams = {
		page: parseInt(parseString(query.page)) || 0,
		terms: parseArray(query.terms || query['terms[]']).map((val) =>
			parseInt(val, 10)
		),
		tags: parseArray(query.tags || query['tags[]']),
		filters: parseArray(query.filters || query['filters[]']),
		type: parseString(query.type),
		label: parseString(query.label),
		query: parseString(query.query),
		partner_related: parseBoolean(query.partner_related)
	}
	const termsSet = new Set(result.terms)
	const tagsSet = new Set(result.tags)

	const createUrlObject = (params: Partial<SearchParams>) => {
		const qryParams: {[k: string]: any} = {}
		if (router.query.lang) qryParams.lang = router.query.lang
		if (router.query.slug) qryParams.slug = router.query.slug

		return {
			pathname: router.pathname,
			query: {
				...qryParams,
				...cleanup(params)
			}
		}
	}
	const updateParams = (newParams: Partial<SearchParams>) => {
		const urlObj = createUrlObject({...result, ...newParams})
		router.push(urlObj, null, {shallow: true, scroll: false})
	}
	const setParams = (newParams: Partial<SearchParams>) => {
		const urlObj = createUrlObject(newParams)
		router.push(urlObj, null, {shallow: true, scroll: false})
	}

	return {
		...result,
		termsSet,
		tagsSet,
		toggleTerm: (id) => {
			if (!id) return
			if (termsSet.has(id)) {
				updateParams({
					page: 0,
					terms: result.terms.filter((term) => term !== id)
				})
			} else {
				updateParams({page: 0, terms: [...result.terms, id]})
			}
		},
		toggleTag: (tag: DPTagTerm | string) => {
			if (!tag) return
			const tagName = typeof tag === 'string' ? tag : tag.name

			if (tagsSet.has(tagName)) {
				updateParams({page: 0, tags: result.tags.filter((t) => t !== tagName)})
			} else {
				updateParams({page: 0, tags: [...result.tags, tagName]})

				if (typeof tag !== 'string') {
					const pingUrl = PING_TAG(tag.id)
					if (pingUrl) unfetch(pingUrl)
				}
			}
		},
		createUrlObject,
		updateParams,
		setParams
	}
}

const parseArray = (value: string | string[] | undefined | null): string[] => {
	if (!value) return []
	if (Array.isArray(value)) return value
	return [value]
}

const parseString = (value: string | string[] | undefined | null): string => {
	if (!value) return undefined
	if (Array.isArray(value)) return value[0]
	return value
}

const parseBoolean = (value: string | string[] | undefined | null): boolean => {
	if (!value) return false
	return true
}

const cleanup = (params: Record<string, any>) => {
	const result = {}

	Object.keys(params).forEach((key) => {
		const value = params[key]
		if (!value) return

		if (Array.isArray(params[key])) {
			if (value.length === 0) return
			result[key + '[]'] = value
		} else {
			result[key] = value
		}
	})

	return result
}
