import React, {createContext} from 'react'

import {DPBlock} from 'types/DPTypes'
import {styler} from 'util/styler/Styler'

const styles = styler('blocks')

export const BlockContext = createContext<{blocks: DPBlock[]}>({blocks: []})

export const Blocks: React.FC<{
	children: React.ReactNode
	fullwidth?: boolean
	blocks: DPBlock[]
}> = ({children, blocks, fullwidth}) => {
	return (
		<div className={styles.mod({fullwidth})()}>
			<BlockContext.Provider value={{blocks}}>{children}</BlockContext.Provider>
		</div>
	)
}
