import {Column, Grid} from 'layout/grid'

import {Block} from 'blocks/block'
import {DPIconintroBlock} from 'types/DPTypes'
import {Image} from 'layout/partials/image'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './iconintro.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'

const styles = fromModule(css)

export const Iconintro: React.FC<DPIconintroBlock> = ({_type, description}) => {
	const {node} = useApp()
	if (node._type !== 'research_project') return null

	return (
		<Block type={_type} className={styles.iconintro()}>
			<Theme.Container>
				<Grid columns={1} s={4} m={4} l={12} align="top">
					<Column s={1} m={1} l={2}>
						<div className={styles.iconintro.icon()}>
							{node.market?.iconmarket_logo && (
								<Image {...node.market?.iconmarket_logo} sizes="170px" />
							)}
						</div>
					</Column>
					<Column s={3} m={3} l={10}>
						<div className={styles.iconintro.description()}>
							{description && <Theme.Wysiwyg>{description}</Theme.Wysiwyg>}
						</div>
					</Column>
				</Grid>
			</Theme.Container>
		</Block>
	)
}
