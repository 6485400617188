import {Column, Grid} from 'layout/grid'

import {Block} from 'blocks/block'
import {DPProfileBlock} from 'types/DPTypes'
import {Image} from 'layout/partials/image'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './profile.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'blocks/anchor/anchor'

const styles = fromModule(css)

export const Profile: React.FC<DPProfileBlock> = (data) => {
	const {_type, image, text} = data
	const id = useIDFromAnchorBlock(data)

	if (!image && !text) return null

	return (
		<Block type={_type} anchorID={id} className={styles.profile()}>
			<Theme.Container>
				<Grid columns={1} s={1} m={2} l={12} align="top">
					<Column l={4}>
						{image?.src && (
							<div className={styles.profile.image()}>
								<Image
									{...image}
									fill
									sizes="(max-width: 768px) 100vw, 460px"
								/>
							</div>
						)}
					</Column>
					<Column l={8}>
						<div className={styles.profile.content()}>
							{text && <Theme.Wysiwyg>{text}</Theme.Wysiwyg>}
						</div>
					</Column>
				</Grid>
			</Theme.Container>
		</Block>
	)
}
