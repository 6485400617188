import { JobsSearchModel } from 'blocks/jobs/models/JobsSearchModel'
import { JobTermModel } from 'blocks/jobs/models/JobTermModel'
import memoizeGetters from 'memoize-getters'
import { RegularJobTermState } from 'blocks/jobs/models/filters/TaxonomyFilterModel'
import { JobModel } from 'blocks/jobs/models/JobModel'

export const jobRegularFilterKeys = [
	'categories',
	'type',
	'promotor',
	'csr',
	'competence',
	'marketsegments',
	'employment_type',
	'employer',
	'job_title',
	'region'
] as const

export const jobExtraFilterKeys = [
	'date_posted'
] as const

export const jobFilterKeys = [...jobRegularFilterKeys, ...jobExtraFilterKeys]
export type JobFilterKey = (typeof jobFilterKeys)[number]
export type JobFilterState = {
	key: JobFilterKey,
	terms: Array<RegularJobTermState>
}

export abstract class JobFilterModel {
	search: JobsSearchModel

	constructor(search: JobsSearchModel,) {
		this.search = search
	}

	abstract get key(): JobFilterKey
	abstract get terms(): Array<JobTermModel>

	get selectedTerms(): Array<JobTermModel> {
		return this.terms.filter(tag => tag.selected)
	}

	get relevantTerms(): Array<JobTermModel> {
		return this.terms.filter(tag => tag.isRelevant || tag.selected)
	}

	validateJob(job: JobModel): boolean {
		if (this.selectedTerms.length == 0) return true
		return !!this.selectedTerms.find(term => term.validateJob(job))
	}

	// ----- Update state ----- //
	enableAll(): JobsSearchModel {
		return this.search.update({
			terms: this.search.selectedTerms
				.filter(term => term.filter !== this)
				.map(term => term.id)
		})
	}
}
memoizeGetters(JobFilterModel)

