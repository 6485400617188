import logoImg from 'assets/images/imec-logo.svg'
import { Theme } from 'layout/theme'
import React, { Fragment } from 'react'
import { DPFooterLinkTerm, DPFooterSocialTerm, DPGeneral } from 'types/DPTypes'
import { useTranslation } from 'util/i18'
import { Link } from 'util/link'
import { fromModule } from 'util/styler/Styler'
import css from './footer.module.scss'
import { Footernav } from './footernav'
import { Sociallink } from './sociallink'

const styles = fromModule(css)

export const Footer: React.FC<DPGeneral['footer']> = (footer) => {

	return (
		<footer className={styles.footer()}>
			<Theme.OuterContainer>
				<div className={styles.footer.content()}>
					<FooterSocial items={footer.social} />
					<Footernav
						categories={footer.categories}
						menu={footer.menu}
					/>
					<FooterBottom items={footer.links} />
				</div>
			</Theme.OuterContainer>
		</footer>
	)
}

export const FooterSocial: React.FC<{
	items: DPFooterSocialTerm[]
}> = ({ items }) => {
	const t = useTranslation()

	if (!items?.length) return null

	return (
		<div className={styles.social()}>
			<p className={styles.social.text()}>{t.footer.follow}</p>
			<div className={styles.social.icons()}>
				{items.map((item, i) => (
					<Sociallink key={i} type={item.name} url={item.link?.url} mod="rounded" />
				))}
			</div>
		</div>
	)
}

export const FooterBottom: React.FC<{
	items: DPFooterLinkTerm[]
}> = ({ items }) => {
	const t = useTranslation()
	const envProjectKey = process.env.NEXT_PUBLIC_PROJECT_KEY
	const subsite = envProjectKey === 'hyperspectral' || envProjectKey === 'iclink'

	return (
		<div className={styles.bottom()}>
			<div className={styles.bottom.row()}>
				<div className={styles.bottom.row.left()}>
					<FooterLinks items={items} />
				</div>
				{subsite && (
					<div className={styles.bottom.row.right()}>
						<p className={styles.bottom.row.right.text()}>
							{t.footer[envProjectKey]}
						</p>
						<Link
							to={'https://imec-int.com'}
							className={styles.bottom.row.right.logo()}
						>
							<img
								src="/assets/img/imec-logo.svg"
								alt="imec logo"
								className={styles.bottom.row.right.logo.img()}
							/>
						</Link>
					</div>
				)}
			</div>
		</div>
	)
}

export const FooterLinks: React.FC<{
	items: DPFooterLinkTerm[]
}> = ({ items }) => {
	if (!items?.length) return null

	return (
		<div className={styles.links()}>
			{items.map((item, i) => {
				return (
					<Fragment key={i}>
						{i > 0 && <span className={styles.links.spacer()}>|</span>}
						<Link className={styles.links.item()} to={item.link?.url}>
							{item.name}
						</Link>
					</Fragment>
				)
			})}
		</div>
	)
}
