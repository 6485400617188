import React, {useState} from 'react'

import {BgFixed} from 'layout/partials/bg'
import {Block} from 'blocks/block'
import {DPNewsletterBlock} from 'types/DPTypes'
import {NEWSLETTER_SUBSCRIBE_URL} from 'util/urls'
import {Theme} from 'layout/theme'
import css from './newsletter.module.scss'
import {fromModule} from 'util/styler/Styler'
import unfetch from 'unfetch'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const Newsletter: React.FC<DPNewsletterBlock> = (data) => {
	const {_type, subtitle, title, description, background, mailchimp_id} = data

	return (
		<Block type={_type} className={styles.newsletter()}>
			{background && <BgFixed image={background} />}
			<Theme.Container>
				<div className={styles.newsletter.content()}>
					<div className={styles.newsletter.content.subtitle()}>{subtitle}</div>
					<div className={styles.newsletter.content.title()}>{title}</div>
					<div className={styles.newsletter.content.description()}>
						<Theme.Wysiwyg>{description}</Theme.Wysiwyg>
					</div>
					<NewsletterForm mailchimp_id={mailchimp_id} />
				</div>
			</Theme.Container>
		</Block>
	)
}

const NewsletterForm: React.FC<{
	mailchimp_id: string
}> = ({mailchimp_id}) => {
	const [email, setEmail] = useState('')
	const [subscribed, setSubscribed] = useState(false)
	const t = useTranslation()

	if (!mailchimp_id) return null

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault()
				if (!email) return

				unfetch(NEWSLETTER_SUBSCRIBE_URL(), {
					method: 'post',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({email, mailchimp_id})
				}).then((res) => {
					if (res.ok === true) {
						setEmail('')
						setSubscribed(true)
					}
				})
			}}
			className={styles.form()}
		>
			<input
				name="email"
				type="email"
				className={styles.form.input()}
				placeholder={
					subscribed
						? t.newsletter.placeholder_success
						: t.newsletter.placeholder
				}
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				required
			/>
			<button type="submit" value="submit" className={styles.form.submit()}>
				{t.newsletter.signup}
			</button>
		</form>
	)
}
