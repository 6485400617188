import {DPNode} from 'types/DPTypes'
import {PreviewType} from 'layout/preview/previewtype'
import queryString from 'query-string'

const LUMEN_URL = process.env.NEXT_PUBLIC_LUMEN_URL
const DRUPAL_URL = process.env.NEXT_PUBLIC_DRUPAL_URL

export const READINGROOM_SEARCH_URL = (options: {
	page: number
	type?: string
	label?: string
	query?: string
	partner_related?: boolean
	tags?: Array<string>
	skip?: Array<DPNode>
}) => {
	const params = queryString.stringify(
		{
			...options,
			skip: (options.skip || []).map((item) => '' + item.node_id)
		},
		{arrayFormat: 'bracket'}
	)

	return `${DRUPAL_URL}/api/search-readingroom?${params}`
}

export const SEARCH_URL = (options: {
	page: number
	query: string
	tags?: Array<string>
	skip?: Array<PreviewType>
}) => {
	//TODO next
	const params = queryString.stringify(options as any, {arrayFormat: 'bracket'})
	return `${DRUPAL_URL}/api/search?${params}`
}

export const SUGGESTIONS_URL = (query: string) => {
	const params = queryString.stringify({query})
	return `${DRUPAL_URL}/api/suggestions?${params}`
}

export const NEWSLETTER_SUBSCRIBE_URL = () => {
	return `${LUMEN_URL}/api/newsletter-subscribe`
}

export const JOBS_SEARCH_URL = (options: {query: string}) => {
	const params = queryString.stringify(options, {arrayFormat: 'bracket'})
	return `${process.env.NEXT_PUBLIC_DRUPAL_URL}/api/cts/search?${params}`
}

export const CONTACT_URL = () => {
	return `${process.env.NEXT_PUBLIC_LUMEN_URL}/api/contact`
}

export const JOB_MAIL_URL = () => {
	return `${process.env.NEXT_PUBLIC_LUMEN_URL}/api/job/mail`
}

export const JOBALERT_URL = () => {
	return `${process.env.NEXT_PUBLIC_LUMEN_URL}/api/jobalert/subscribe`
}

export const PING_URL = (nodeID: number | string) => {
	if (!process.env.NEXT_PUBLIC_DRUPAL_URL) return null
	return `${process.env.NEXT_PUBLIC_DRUPAL_URL}/api/ping-page/${nodeID}`
}

export const PING_TAG = (termID: number | string) => {
	if (!process.env.NEXT_PUBLIC_DRUPAL_URL) return null
	return `${process.env.NEXT_PUBLIC_DRUPAL_URL}/api/ping-tag/${termID}`
}

export const EDIT_URL = (type: 'node' | 'term', nodeID: number | string) => {
	const lang = process.env.NEXT_PUBLIC_DRUPAL_LANG
	if (type === 'term') {
		return `${process.env.NEXT_PUBLIC_DRUPAL_URL}/${lang}/taxonomy/term/${nodeID}/edit`
	}
	return `${process.env.NEXT_PUBLIC_DRUPAL_URL}/${lang}/node/${nodeID}/edit`
}

export const PREVIEW_URL = (token: string) => {
	return `${process.env.NEXT_PUBLIC_DRUPAL_URL}/api/preview_new/${
		process.env.NEXT_PUBLIC_DRUPAL_LANG || 'en'
	}/${token}`
}

export const DEV_PREVIEW_URL = (nodeID: number | string) => {
	return `${process.env.NEXT_PUBLIC_DRUPAL_URL}/api/devpreview/${
		process.env.NEXT_PUBLIC_DRUPAL_LANG || 'en'
	}/${nodeID}`
}
