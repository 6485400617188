import {DPJob, DPRelatedjobsBlock} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {Link} from 'util/link'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './relatedjobs.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useLinks} from 'util/links'

const styles = fromModule(css)

export const Relatedjobs: React.FC<
	DPRelatedjobsBlock & {jobdetail?: boolean}
> = (data) => {
	const {_type, text_left, title_right, jobs, jobdetail} = data
	const links = useLinks()

	if (jobdetail)
		return <RelatedjobsJobdetailView jobs={jobs} title={title_right} />

	if (text_left) {
		return (
			<RelatedjobsContentView
				_type={_type}
				jobs={jobs}
				title={title_right}
				content={text_left}
				jobsurl={links.jobs.url}
			/>
		)
	}

	return (
		<Block type={_type} className={styles.relatedjobs()}>
			<Theme.Container>
				{title_right && (
					<Theme.H2
						className={styles.relatedjobs.title()}
						mod={['blueline', 'arrow']}
					>
						<Link to={links.jobs.url}>{title_right}</Link>
					</Theme.H2>
				)}
				<div className={styles.relatedjobs.block()}>
					{(jobs || []).slice(0.6).map((item, i) => (
						<RelatedjobsItem {...item} key={`${i}-${item.id}`} />
					))}
				</div>
			</Theme.Container>
		</Block>
	)
}

const RelatedjobsContentView: React.FC<{
	_type: DPRelatedjobsBlock['_type']
	content: string
	title: string
	jobs: DPJob[]
	jobsurl: string
}> = ({_type, content, title, jobs, jobsurl}) => {
	return (
		<Block type={_type} className={styles.relatedjobs.mod('contentview')()}>
			<div className={styles.contentview()}>
				<div className={styles.contentview.content()}>
					<Theme.Wysiwyg>{content}</Theme.Wysiwyg>
				</div>
				<div className={styles.contentview.list()}>
					<Link to={jobsurl}>
						<Theme.H2 mod={['blueline', 'arrow', 'white']}>{title}</Theme.H2>
					</Link>
					<div className={styles.contentview.jobs()}>
						{(jobs || []).slice(0.4).map((item, i) => (
							<RelatedjobsItem
								{...item}
								key={`${i}-${item.id}`}
								contentview={true}
							/>
						))}
					</div>
				</div>
			</div>
		</Block>
	)
}

const RelatedjobsJobdetailView: React.FC<{jobs: DPJob[]; title: string}> = ({
	jobs,
	title
}) => {
	const links = useLinks()

	return (
		<div className={styles.relatedjobs.mod('jobdetail')()}>
			<Theme.Container>
				{title && (
					<Theme.H4 className={styles.jobdetailview.title()}>{title}</Theme.H4>
				)}
				{jobs?.length > 0 && (
					<div className={styles.jobdetailview.items()}>
						{(jobs || []).map((item, i) => (
							<RelatedjobsItem
								{...item}
								key={`${i}-${item.id}`}
								jobdetail={true}
							/>
						))}
					</div>
				)}
				<Theme.Button
					to={links.jobs.url}
					className={styles.jobdetailview.button()}
					mod="rounded"
				>
					{links.jobs.title}
				</Theme.Button>
			</Theme.Container>
		</div>
	)
}

export const RelatedjobsItem: React.FC<
	DPJob & {jobdetail?: boolean; contentview?: boolean}
> = (data) => {
	const {title, short_description, url, jobdetail, contentview} = data

	return (
		<Link
			to={url}
			className={styles.item.mod({contentview}).mod({jobdetail})()}
		>
			{title && (
				<Theme.H4 className={styles.item.title()} mod="light">
					{title}
				</Theme.H4>
			)}
			{jobdetail && short_description && (
				<Theme.Wysiwyg className={styles.item.description()}>
					{short_description.replace(/<[^>]*>?/g, '').substring(0, 280)}
				</Theme.Wysiwyg>
			)}
			{!jobdetail && !contentview && <div>TODO: Category - Location</div>}
			{!jobdetail && !contentview && (
				<div className={styles.item.publishdate()}>TODO: When posted</div>
			)}
		</Link>
	)
}
