import type {DPFullNode, DPGeneral} from 'types/DPTypes'
import {
	FetchGeneralResult,
	FetchNodeResult,
	FetchRoutesResponse,
	fetchGeneralData,
	fetchNodeData,
	fetchRoutes
} from 'server/data'
import {GetStaticPaths, GetStaticProps} from 'next'

import {Article} from 'nodes/article'
import {Case} from 'nodes/case'
import {Dossier} from 'nodes/dossier'
import {Event} from 'nodes/event'
import {Job} from 'nodes/job'
import {Page} from 'nodes/page'
import {Press} from 'nodes/press'
import {Product} from 'nodes/product'
import React from 'react'
import {Researchproject} from 'nodes/researchproject'
import {Videopage} from 'nodes/videopage'

export type PageData = {
	node: DPFullNode
	general: DPGeneral
	debug: {
		region: string
		node: FetchNodeResult['_debug']
		routes: FetchRoutesResponse['_debug']
		general: FetchGeneralResult['_debug']
		getStaticPropsDuration: number
		doubleFetchDuration: number
	}
}

const DrupalPage: React.FC<DPFullNode> = (data: DPFullNode) => {
	switch (data._type) {
		case 'page':
			return <Page {...data} />
		case 'case':
			return <Case {...data} />
		case 'event':
			return <Event {...data} />
		case 'press':
			return <Press {...data} />
		case 'article':
			return <Article {...data} />
		case 'product':
			return <Product {...data} />
		case 'research_project':
			return <Researchproject {...data} />
		case 'job':
			return <Job {...data} />
		case 'dossier':
			return <Dossier {...data} />
		case 'video_page':
			return <Videopage {...data} />
	}
	return null
}
export default DrupalPage

export const getStaticPaths: GetStaticPaths = async () => {
	let data: Array<string> = []

	if (process.env.PRELOAD) {
		const res = await fetch(process.env.NEXT_PUBLIC_DRUPAL_URL + '/api/paths')
		data = await res.json()
	} else {
		const res = await fetch(
			process.env.NEXT_PUBLIC_DRUPAL_URL + '/api/prebuild-paths'
		)
		data = await res.json()
	}

	return {
		fallback: 'blocking',
		paths: data.map((url) => ({
			params: {slug: url.split('/').slice(1)}
		}))
	}
}

export const getStaticProps: GetStaticProps<PageData> = async (context) => {
	const pieces = context.params!.slug as string[]
	const slug = '/' + pieces.join('/')
	const lang = pieces[0] || process.env.NEXT_PUBLIC_DRUPAL_LANG || 'en'
	const startTime = Date.now()

	const routes = await fetchRoutes()
	const routesFetchedDuration = Date.now() - startTime
	if (routes.redirects[slug]) {
		return {
			redirect: {
				destination: routes.redirects[slug],
				permanent: true
			},
			revalidate: 60 * 5
		}
	}
	if (!routes.urls.find((url) => url === slug)) {
		const redirect = routes.urls.find(
			(url) => url === `/en${slug}` || url === `/nl${slug}`
		)
		if (redirect) {
			return {
				redirect: {
					destination: redirect,
					permanent: true
				},
				revalidate: 60 * 5
			}
		}

		if (!lang || lang.length !== 2) {
			return {
				notFound: true,
				revalidate: 60 * 5
			}
		}

		// return {
		// 	notFound: true,
		// 	revalidate: 60 * 5
		// }
	}

	let doubleFetchTime = Date.now()
	const [node, general] = await Promise.all([
		fetchNodeData(slug),
		fetchGeneralData(lang)
	])
	let doubleFetchDuration = Date.now() - doubleFetchTime

	if (!node) {
		return {
			notFound: true,
			revalidate: 60 * 5
		}
	}

	if (Object.keys(node).length === 1 && node._debug) {
		return {
			notFound: true,
			revalidate: 60 * 5
		}
	}

	let revalidate = 60 * 5
	if (node._updated == new Date().toISOString().slice(0, 10)) {
		revalidate = 60
	}

	return {
		props: {
			node,
			general,
			debug: {
				region: process.env.NOW_REGION || null,
				node: node._debug,
				routes: routes._debug,
				general: general._debug,
				routesFetchedDuration,
				doubleFetchDuration,
				getStaticPropsDuration: Date.now() - startTime
			}
		},
		revalidate
	}
}
