import {DPAnchorBlock, DPBlock} from 'types/DPTypes'
import React, {useContext} from 'react'

import {BlockContext} from 'blocks/blocks'

export const Anchor: React.FC<DPAnchorBlock> = (data) => {
	const {id} = data
	const hide = useHideAnchorBlock(data)

	if (!id) return null
	if (hide) return null

	console.warn('Anchor block is found, but not substituted on page')

	return <div id={`${id}`.replace('#', '')} />
}

const useHideAnchorBlock = (block: DPAnchorBlock) => {
	const context = useContext(BlockContext)
	const index = context.blocks.findIndex((bl) => bl.id === block.id)
	const nextBlock = context.blocks[index + 1]

	if (index === -1) return false
	if (index === context.blocks.length - 1) return false
	if (!nextBlock) return false
	return !!supportedAnchorBlockTypes.find((type) => type === nextBlock._type)
}

export function useIDFromAnchorBlock<T extends SupportedAnchorBlockType>(
	block: DPBlock & {_type: T}
) {
	const context = useContext(BlockContext)
	const index = context.blocks.findIndex((bl) => bl.id === block.id)
	const prevBlock = context.blocks[index - 1]

	if (!prevBlock) return null
	if (prevBlock._type !== 'anchor') return null
	return prevBlock.id
}

export const supportedAnchorBlockTypes = [
	'agenda',
	'backgroundblock',
	'centered',
	'contact',
	'contactform',
	'eloqua',
	'imagetext',
	'iframeblock',
	'overview',
	'profile',
	'prototype',
	'related',
	'testimonials',
	'textblock',
	'videoblock'
] as const
export type SupportedAnchorBlockType = typeof supportedAnchorBlockTypes[number]
export type NonSupportedAnchorBlockType = Exclude<
	DPBlock['_type'],
	SupportedAnchorBlockType
>
