import {DPFullNode, DPGeneral} from 'types/DPTypes'
import {PropsWithChildren, createContext, useContext} from 'react'

import {CookiehubContextProvider} from 'util/useCookiehub'

export type AppContextType = {
	general: DPGeneral
	node: DPFullNode | null
	lang: AppLanguage
}

export type AppLanguage = 'nl' | 'en'

const AppContext = createContext<AppContextType | false>(false)

export const AppContextProvider: React.FC<
	Omit<PropsWithChildren & AppContextType, 'searchClient'>
> = ({children, ...ctx}) => {
	return (
		<AppContext.Provider value={ctx}>
			<CookiehubContextProvider>{children}</CookiehubContextProvider>
		</AppContext.Provider>
	)
}

export const useApp = () => {
	const result = useContext(AppContext)
	if (!result)
		throw new Error(
			'Trying to use the useApp hook, but no AppContext is defined.'
		)
	return result
}
