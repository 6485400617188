import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { isBrowser } from "browser-or-node"

export const useSearchParamsAreLoaded = () => {
	const [isLoaded, setIsLoaded] = useState(false)
	const router = useRouter()
	useEffect(() => {
		if (!isBrowser) return
		if (isLoaded) return
		if (!window.location.search) {
			setIsLoaded(true)
			return
		}

		const queryKeys = Object.keys(router.query)
		if (!queryKeys.length) return
		if (queryKeys.length === 1 && queryKeys[0] === 'slug') return
		setIsLoaded(true)
	})
	return isLoaded
}