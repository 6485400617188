import {AppContextProvider, AppContextType} from 'AppContext'
import {DPFullNode, DPGeneral} from 'types/DPTypes'
import {GetStaticPaths, GetStaticProps} from 'next'
import {useEffect, useState} from 'react'

import {AppProps} from 'next/app'
import DrupalPage from '../[...slug]'
import {Layout} from 'layout/layout'

const PreviewPage: React.FC<AppProps<any>> = ({pageProps}) => {
	const general: DPGeneral = pageProps.general
	const lang = pageProps.lang
	const [state, setState] = useState<AppContextType & {message: any}>(null)

	useEffect(() => {
		const receiveMessage = (message) => {
			if (state) return
			console.log(message)
			if (!message.data || !message.data.node_id) return
			const node: DPFullNode = message.data
			setState({...state, general, node, message, lang: node.langcode})
		}
		window.addEventListener('message', receiveMessage)
		localStorage.admin_enabled = 'TRUE'
		localStorage.admin_refreshed = Date.now()
		return () => {
			window.removeEventListener('message', receiveMessage)
		}
	})

	if (!state) return <div>Loading preview..</div>
	if (!state.node?.url)
		return <div>Failed to load, message: {JSON.stringify(state.message)}</div>
	const node = state.node

	return (
		<AppContextProvider general={general} node={node} lang={lang}>
			{node?._type !== 'article' && (
				<Layout>
					<DrupalPage {...node} />
				</Layout>
			)}
			{node?._type === 'article' && <DrupalPage {...node} />}
		</AppContextProvider>
	)
}

export default PreviewPage

export const getStaticPaths: GetStaticPaths = async () => {
	return {
		paths: ['nl', 'en', 'fr'].map((lang) => ({
			params: {lang}
		})),
		fallback: false
	}
}

export const getStaticProps: GetStaticProps = async (context) => {
	const lang = context.params!.lang as string
	const resGeneral = await fetch(
		process.env.NEXT_PUBLIC_DRUPAL_URL + `/api/general/${lang}`
	)
	const general: any = await resGeneral.json()

	return {
		props: {
			general,
			lang
		},
		revalidate: 15
	}
}
