import {BgFixed} from 'layout/partials/bg'
import {Block} from 'blocks/block'
import {DPCenteredBlock} from 'types/DPTypes'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './centered.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useIDFromAnchorBlock} from 'blocks/anchor/anchor'

const styles = fromModule(css)

export const Centered: React.FC<DPCenteredBlock> = (data) => {
	const {_type, background, text} = data
	const {node} = useApp()
	const id = useIDFromAnchorBlock(data)

	const darkText = background?.use_dark_colors
	const isArticle = node?._type === 'article'

	return (
		<Block
			type={_type}
			anchorID={id}
			className={styles.centered.mod({
				article: isArticle,
				background,
				darkText: darkText
			})()}
		>
			{background && <BgFixed image={background} sizes="1580px" />}
			<Theme.Container>
				<div className={styles.centered.content()}>
					{text && <Theme.Wysiwyg mod={'inherit'}>{text}</Theme.Wysiwyg>}
				</div>
			</Theme.Container>
		</Block>
	)
}
