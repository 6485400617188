import {DPTagTerm} from 'types/DPTypes'
import {Link} from 'util/link'
import React from 'react'
import css from './tags.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useReadingroomSearchParams} from 'blocks/readingroom/readingroom'

const styles = fromModule(css)

export const Tags: React.FC<{tags: DPTagTerm[]; tabIndex?: number}> = ({ tags, tabIndex }) => {
	const {createUrlObject} = useReadingroomSearchParams()

	if (!tags || tags.length === 0) return null

	return (
		<div className={styles.tags()}>
			{tags.slice(0, 2).map(tag => (
				<div key={tag.id} className={styles.tags.item()}>
					<Link
						to={createUrlObject({ tags: [tag.name] })}
						tabIndex={tabIndex}
						className={styles.tags.item.tag()}
					>
						{tag?.name}
					</Link>
				</div>
			))}
			{tags.length > 2 && <span className={styles.tags.dots()}>...</span>}
		</div>
	)
}
