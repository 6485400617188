import { BgFixed } from 'layout/partials/bg'
import { Tags } from 'layout/partials/tags'
import { Theme } from 'layout/theme'
import React from 'react'
import { DPNode } from 'types/DPTypes'
import { useTranslation } from 'util/i18'
import { Link } from 'util/link'
import { fromModule } from 'util/styler/Styler'
import css from './relatedpreview.module.scss'

const styles = fromModule(css)

export const Relatedpreview: React.FC<DPNode> = data => {
	const { title, tags, image, url } = data
	const t = useTranslation()

	const label = t.getNodeLabel(data)

	return (
		<div className={styles.relatedpreview()}>
			<Link to={url} className={styles.relatedpreview.image()}>
				<BgFixed image={image} className={styles.relatedpreview.image.bg()} mod="gradient" />
			</Link>
			{label && <p className={styles.relatedpreview.label()}>{label}</p>}
			<div className={styles.relatedpreview.content()}>
				{tags && tags.length !== 0 && (
					<div className={styles.relatedpreview.content.tags()}>
						<Tags tags={tags} />
					</div>
				)}
				<Link to={url}>
					<Theme.H5 className={styles.relatedpreview.content.title()}>{title}</Theme.H5>
				</Link>
			</div>
		</div>
	)
}
