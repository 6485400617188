import React, {Fragment, useEffect, useRef, useState} from 'react'

import {Block} from 'blocks/block'
import {CookieMissing} from 'layout/cookies/cookiemissing'
import {DPIframeblockBlock} from 'types/DPTypes'
import InnerHTML from 'dangerously-set-html-content'
import {Theme} from 'layout/theme'
import css from './iframeblock.module.scss'
import {fromModule} from 'util/styler/Styler'
import {slugify} from 'util/slugify'
import {useIDFromAnchorBlock} from 'blocks/anchor/anchor'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const IframeBlock: React.FC<DPIframeblockBlock> = (data) => {
	const dom = useRef<HTMLDivElement>()
	const [visible, setVisible] = useState(false)
	const id = useIDFromAnchorBlock(data)
	const t = useTranslation()
	const {
		_type,
		title,
		fullwidth,
		ratio_enabled,
		aspectratio,
		embed_code,
		arialabel,
		minwidth,
		hidetext
	} = data

	useEffect(() => {
		setVisible(!minwidth || window.innerWidth > minwidth)
		const resize = () => setVisible(!minwidth || window.innerWidth > minwidth)
		window.addEventListener('resize', resize)

		const clear = []
		const iframes: NodeListOf<HTMLIFrameElement> =
			dom?.current?.querySelectorAll('iframe')
		if (iframes?.length) {
			iframes.forEach((iframe) => {
				if (!iframe) return null
				iframe.setAttribute('loading', 'lazy')
				arialabel && iframe.setAttribute('aria-label', arialabel)
			})
		}

		return () => {
			window.removeEventListener('resize', resize)
			clear.forEach((f) => f())
		}
	}, [minwidth])

	if (!embed_code) return null
	if (!visible) {
		return (
			<Block type="iframeblock" anchorID={id} className={styles.iframeblock()}>
				<Theme.Container>
					<p>{hidetext || t.iframe.invisible}</p>
				</Theme.Container>
			</Block>
		)
	}

	const WrapperTag = fullwidth ? Fragment : Theme.Container

	return (
		<Block
			type={_type}
			anchorID={id}
			className={styles.iframeblock.mod({fullwidth})()}
		>
			{title && (
				<Theme.Container>
					<Theme.H2
						id={slugify(title)}
						className={styles.iframeblock.title()}
						mod="blueline"
					>
						{title}
					</Theme.H2>
				</Theme.Container>
			)}
			<WrapperTag>
				<div
					className={styles.iframeblock.iframecontainer.mod(
						(ratio_enabled || aspectratio) && 'aspectratio'
					)()}
				>
					<CookieMissing object={embed_code}>
						<IframeTrusted ariaLabel={arialabel}>{embed_code}</IframeTrusted>
					</CookieMissing>
				</div>
			</WrapperTag>
		</Block>
	)
}

const IframeTrusted: React.FC<{
	className?: string
	children: string
	ariaLabel?: string
}> = ({children, ariaLabel, ...props}) => {
	const dom = useRef<HTMLDivElement>()

	useEffect(() => {
		const clear = []

		const iframes: NodeListOf<HTMLIFrameElement> =
			dom?.current?.querySelectorAll('iframe')

		if (iframes?.length) {
			iframes.forEach((iframe) => {
				if (!iframe) return null
				iframe.setAttribute('loading', 'lazy')
				ariaLabel && iframe.setAttribute('aria-label', ariaLabel)
			})
		}

		return () => {
			clear.forEach((f) => f())
		}
	})

	return (
		<InnerHTML
			{...props}
			html={Array.isArray(children) ? children[0] : children}
		/>
		// <div
		// 	ref={dom}
		// 	{...props}
		// 	dangerouslySetInnerHTML={{
		// 		__html:
		// 	}}
		// />
	)
}
