import {Form, Formik} from 'formik'
import {FormField, FormWarning} from 'layout/form/form'
import React, {useContext, useState} from 'react'
import {
	TaxonomyFilterModel,
	TaxonomyTermModel
} from 'blocks/jobs/models/filters/TaxonomyFilterModel'

import {JOBALERT_URL} from 'util/urls'
import {JobsContext} from 'blocks/jobs/jobs'
import {Theme} from 'layout/theme'
import css from './jobalerts.module.scss'
import fetch from 'unfetch'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export type JobAlertsData = {
	email?: string
}

type JobAlertsState = 'initial' | 'no_filter' | 'submitting' | 'finished'

export const JobAlerts: React.FC = () => {
	const {search} = useContext(JobsContext)
	const t = useTranslation()
	const [state, setState] = useState<JobAlertsState>('initial')
	const needsFilter = !search || !search.numberOfSelectedTerms

	let msg = ''
	if (state === 'no_filter')
		msg = 'Please select at least 1 filter below to apply.'
	if (state === 'finished')
		msg = 'Thank you for subscribing. Your preferences are saved.'

	return (
		<div className={styles.jobalerts()}>
			<Formik<JobAlertsData>
				initialValues={{}}
				onSubmit={(values, {setErrors, resetForm}) => {
					if (state === 'submitting') return

					const categories: any = {}
					search.filters.forEach((filter) => {
						if (!(filter instanceof TaxonomyFilterModel)) return
						if (!filter.selectedTerms.length) return
						categories[filter.key] = filter.selectedTerms.map((term) => ({
							title: (term as TaxonomyTermModel).name,
							id: (term as TaxonomyTermModel).tid,
							parent: (term as TaxonomyTermModel).parent?.tid
						}))
					})

					if (!Object.keys(categories)) {
						setState('no_filter')
						return
					}

					setState('submitting')

					fetch(JOBALERT_URL(), {
						method: 'POST',
						headers: {'Content-Type': 'application/json'},
						body: JSON.stringify({
							...values,
							categories,
							jobtype: search.category,
							query: search.query
						})
					}).then((response) => {
						if (response.ok) {
							resetForm()
							setState('finished')
						} else {
							setState('initial')
							return response.json().then((errors) => setErrors(errors))
						}
					})
				}}
			>
				<Form className={styles.jobalerts.form()}>
					<p className={styles.jobalerts.notice()}>
						Notify me when new opportunities match my search
					</p>
					<FormField
						name="email"
						type="email"
						required
						mod="dark"
						label={t.form.email}
					/>
					<FormField
						name="agree_terms"
						type="checkbox"
						required
						label="I agree that imec can send me email alerts"
					/>
					<div className={styles.jobalerts.button()}>
						<Theme.Button
							as="button"
							type="submit"
							mod={['rounded', 'outline', 'white']}
						>
							<Theme.ButtonText>Activate</Theme.ButtonText>
							<Theme.ButtonIcon />
						</Theme.Button>
					</div>
					<FormWarning warning={msg} />
				</Form>
			</Formik>
		</div>
	)
}
