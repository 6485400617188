import {Cardpreview, CardpreviewContent} from 'layout/preview/cardpreview'
import {Column, Grid} from 'layout/grid'
import {
	DPOverviewBlock,
	DPOverviewItemBlock,
	DPOverviewLinkBlock,
	DPOverviewVideoBlock
} from 'types/DPTypes'

import {Block} from 'blocks/block'
import React from 'react'
import {Textpreview} from 'layout/preview/textpreview'
import {Theme} from 'layout/theme'
import {VideoBlock} from 'blocks/videoblock/videoblock'
import css from './overview.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'blocks/anchor/anchor'

const styles = fromModule(css)

type OverviewDisplaysType = {
	display_textual?: boolean
}

export const Overview: React.FC<DPOverviewBlock> = (data) => {
	const {
		_type,
		title,
		columns = 3,
		items,
		textual_display,
		white_background
	} = data
	const id = useIDFromAnchorBlock(data)
	if (!items || items.length === 0) return null
	const bgwhite = white_background
	const display_textual = textual_display

	return (
		<Block
			type={_type}
			anchorID={id}
			className={styles.overview.mod({bgwhite}).mod(`columns${columns}`)()}
		>
			<Theme.Container>
				{title && (
					<div className={styles.overview.title()}>
						<Theme.H2 mod="blueline">{title}</Theme.H2>
					</div>
				)}
				<Grid columns={1} s={2} {...OverviewColumns({columns})} gap={[30, 60]}>
					{items.map((item, i) => (
						<Column key={i}>
							<OverviewItem
								columns={columns}
								item={item}
								display_textual={display_textual}
							/>
						</Column>
					))}
				</Grid>
			</Theme.Container>
		</Block>
	)
}

const OverviewColumns = ({columns}) => {
	switch (true) {
		case columns === 2:
			return {m: 2, l: 2}
		case columns === 3:
			return {m: 2, l: 3}
		default:
			return {m: 2, l: 4}
	}
}

const OverviewItem: React.FC<
	{
		columns?: number
		item: DPOverviewItemBlock | DPOverviewLinkBlock | DPOverviewVideoBlock
	} & OverviewDisplaysType
> = ({columns, item, display_textual}) => {
	switch (item._type) {
		case 'overview_item':
			if (display_textual) {
				return <Textpreview text={item.text} link={item.link} />
			}

			return (
				<Cardpreview
					url={item.link?.url}
					image={item.image}
					text={item.text}
					text_mod={columns === 4 && ['small', 'full']}
					display_fallback={!item.image}
				/>
			)
		case 'overview_link':
			if (display_textual) {
				return (
					<Textpreview
						text={`
						<h5>${item.link?.title}</h5>
						<p>${item.link?.description}</p>
					`}
						link={item.link}
					/>
				)
			}

			return (
				<Cardpreview
					url={item.link?.url}
					image={item.link?.image}
					text={`
						<h5>${item.link?.title}</h5>
						<p>${item.link?.description}</p>
					`}
					text_mod={columns === 4 && ['small', 'full']}
					display_fallback={!item.link?.image}
				/>
			)
		case 'overview_video':
			if (display_textual) {
				return <Textpreview text={item.text} link={null} />
			}
			return (
				<div className={styles.videoblock()}>
					{item.text_above && (
						<CardpreviewContent
							text={item.text}
							text_mod={columns === 4 ? ['small', 'full', 'top'] : ['top']}
						/>
					)}
					<VideoBlock
						_type="videoblock"
						embed_code={item.embed_code}
						id={null}
						size={null}
						overview={true}
					/>
					{!item.text_above && (
						<CardpreviewContent
							text={item.text}
							text_mod={columns === 4 && ['small', 'full']}
						/>
					)}
				</div>
			)
	}
}
