import {Column, Grid} from 'layout/grid'
import {DPIconOverviewBlock, DPResearchProject} from 'types/DPTypes'
import {FilterType, Filters} from 'layout/partials/filters'
import {ImageType, WysiwygType} from 'types/basictypes'

import {Image} from 'layout/partials/image'
import {Link} from 'util/link'
import React from 'react'
import {SearchresultsMore} from 'layout/search/searchresults'
import {Theme} from 'layout/theme'
import css from './iconoverview.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useSearchParams} from 'util/useSearchParams'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const iconFilterKeys = [
	'market',
	'research_project_type',
	'status',
	'year'
]
export type IconFilterKey = typeof iconFilterKeys[number]

export type IconOverviewData = {
	types: Record<IconFilterKey, IconTerm[]>
	items: DPResearchProject[]
}

export type IconTerm = {
	name: string
	id: number
}

export type IconItem = {
	title: string
	url: string
	description: WysiwygType
	image: ImageType
	terms: Array<number>
}

export type IconOverviewType = {}

export const filterItems = (data: IconOverviewData, termsSet: Set<number>) => {
	const activeFilters = Object.keys(data.types)
		.map((key) => {
			const ids = data.types[key].map((term) => term.id)
			return new Set(ids.filter((id) => termsSet.has(id)))
		})
		.filter((filter) => filter.size > 0)

	const failedItems = new Set()
	activeFilters.forEach((filter) => {
		data.items.forEach((item) => {
			if (failedItems.has(item)) return
			if (!item.terms.find((term) => filter.has(term))) {
				failedItems.add(item)
			}
		})
	})

	return data.items.filter((item) => !failedItems.has(item))
}

export const Iconoverview: React.FC<DPIconOverviewBlock> = ({
	items: allItems,
	markets,
	statuses,
	types,
	years
}) => {
	const data: IconOverviewData = {
		items: [...allItems].map((item, i) => ({...item, node_id: i})),
		types: {
			market: markets,
			research_project_type: types,
			status: statuses,
			year: years
		}
	}
	const {page, termsSet, updateParams} = useSearchParams()
	const {iconoverview} = useTranslation()
	const items = filterItems(data, termsSet)
	const visible = items.slice(0, (page + 1) * 10)
	const hasMore = visible.length < items.length
	const filters: Array<FilterType> = iconFilterKeys.map((key) => ({
		title: iconoverview.filters[key],
		key: key,
		options: data.types[key].map((term) => ({id: term.id, name: term.name}))
	}))

	return (
		<div className={styles.iconoverview()}>
			<Filters filters={filters} />
			<div className={styles.iconoverview.space()}></div>
			<div className={styles.iconoverview.content()}>
				{visible.map((item) => (
					<IconoverviewProject item={item} key={item.node_id} />
				))}
			</div>
			{hasMore && (
				<SearchresultsMore onClick={() => updateParams({page: page + 1})} />
			)}
		</div>
	)
}

export const IconoverviewProject: React.FC<{
	item: DPResearchProject
}> = ({item}) => {
	const t = useTranslation()

	return (
		<div className={styles.project()}>
			<Theme.Container>
				{
					<Grid columns={1} s={1} m={2} l={12} align="top">
						<Column l={3}>
							<div className={styles.project.image()}>
								{item.background_image && (
									<Link to={item.url} style={{display: 'block'}}>
										<Image
											{...item.background_image}
											sizes="(max-width: 768px) 100vw, 460px"
										/>
									</Link>
								)}
							</div>
						</Column>
						<Column l={9}>
							<div className={styles.project.content()}>
								<Link to={item.url} className={styles.project.content.title()}>
									{item.title}
								</Link>
								<Theme.Wysiwyg className={styles.project.content.description()}>
									{item.description}
								</Theme.Wysiwyg>
								<Link to={item.url} className={styles.project.content.link()}>
									{t.iconoverview.readmore}
								</Link>
							</div>
						</Column>
					</Grid>
				}
			</Theme.Container>
		</div>
	)
}
