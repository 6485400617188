import {Magazine, MagazineItems} from 'blocks/readingroom/components/magazine'

import {Blocks} from 'blocks/blocks'
import {DPDossierNode} from 'types/DPTypes'
import {Hero} from 'blocks/hero/hero'
import React from 'react'
import {useTranslation} from 'util/i18'

export const Dossier: React.FC<DPDossierNode> = (node) => {
	const t = useTranslation()
	const {title, background_image, articles} = node

	return (
		<Blocks blocks={[]}>
			<Hero
				subtitle={t.dossier}
				title={title}
				background_image={background_image}
				mod="small"
			/>
			{articles?.length > 0 && (
				<Magazine>
					<MagazineItems items={articles} />
				</Magazine>
			)}
		</Blocks>
	)
}
