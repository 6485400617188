import {Theme} from 'layout/theme'
import React from 'react'
import {DPLink} from 'types/DPTypes'
import {useTranslation} from 'util/i18'
import {fromModule} from 'util/styler/Styler'
import css from './textpreview.module.scss'

const styles = fromModule(css)

export type TextpreviewType = {
	text: string
	link?: DPLink
}

export const Textpreview: React.FC<TextpreviewType> = (data) => {
	const {text, link} = data
	const {read_more} = useTranslation()

	return (
		<div className={styles.textpreview()}>
			{text && (
				<Theme.Wysiwyg className={styles.textpreview.wysiwyg()}>
					{text}
				</Theme.Wysiwyg>
			)}
			{link && link.url && (
				<Theme.Link to={link?.url} className={styles.textpreview.link()}>
					<Theme.LinkText>{link?.title || read_more}</Theme.LinkText>
					<Theme.LinkIcon />
				</Theme.Link>
			)}
		</div>
	)
}
