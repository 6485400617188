import {Block} from 'blocks/block'
import {DPTextblockBlock} from 'types/DPTypes'
import {Theme} from 'layout/theme'
import css from './textblock.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useIDFromAnchorBlock} from 'blocks/anchor/anchor'

const styles = fromModule(css)

export const Textblock: React.FC<DPTextblockBlock> = (data) => {
	const {text, display_grey, display_dark} = data
	const {node} = useApp()
	const id = useIDFromAnchorBlock(data)
	if (!text) return null

	return (
		<Block
			type="textblock"
			className={styles.textblock
				.is({dark: display_dark})
				.mod({grey: display_grey})
				.mod(node?._type)()}
			anchorID={id}
		>
			<Theme.Container>
				<div className={styles.textblock.content()}>
					<Theme.Wysiwyg>{text}</Theme.Wysiwyg>
				</div>
			</Theme.Container>
		</Block>
	)
}
