import {Carousel, useCarousel} from '@codeurs/carousel'
import {DPArticle, DPArticlesBlock, DPPress} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {CarouselControls} from 'layout/carousel/carouselcontrols'
import {CarouselType} from 'types/basictypes'
import {Icon} from 'assets/icon'
import {Imagepreview} from 'layout/preview/imagepreview'
import {Link} from 'util/link'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './articles.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useLinks} from 'util/links'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const Articles: React.FC<DPArticlesBlock> = (data) => {
	const {_type, title, articles, populair_label, populair_tags} = data
	const carousel = useCarousel()
	const {readingroom} = useLinks()

	return (
		<Block type={_type} className={styles.articles()}>
			<Theme.Container>
				<div className={styles.articles.top()}>
					<div className={styles.articles.top.title()}>
						<Link to={readingroom.url}>
							<Theme.H2 mod={['blueline', 'arrow']}>
								{title || readingroom.title}
							</Theme.H2>
						</Link>
					</div>
					{carousel && carousel.total > 1 && (
						<div className={styles.articles.top.nav()}>
							<CarouselControls carousel={carousel} />
						</div>
					)}
				</div>
				<ArticlesSubjects label={populair_label} tags={populair_tags} />
			</Theme.Container>
			<ArticlesContent carousel={carousel} articles={articles} />
		</Block>
	)
}

export const ArticlesSubjects: React.FC<{
	label: string
	tags: Array<{name?: string}>
}> = (data) => {
	const {label, tags} = data
	const t = useTranslation()
	const {readingroom} = useLinks()
	const tagUrl = readingroom.url + '?tags='

	if (!tags || tags.length === 0) return null

	return (
		<div className={styles.subjects()}>
			{label && <Theme.H5>{label}</Theme.H5>}
			<div className={styles.subjects.list()}>
				{tags.map((tag, i) => (
					<div key={i} className={styles.subjects.list.item()}>
						<div className={styles.subjects.list.item.tag()}>
							<Link
								to={tagUrl + tag.name}
								className={styles.subjects.list.item.tag.label()}
							>
								{tag.name}
							</Link>
						</div>
					</div>
				))}
				<div className={styles.subjects.list.item()}>
					<div className={styles.subjects.list.item.tag.mod('link')()}>
						<Theme.Link to={readingroom.url + '?open=1'}>
							<Theme.LinkText>{t.words.show_all}</Theme.LinkText>
							<Theme.LinkIcon />
						</Theme.Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export const ArticlesContent: React.FC<{
	carousel: CarouselType
	articles: Array<DPArticle | DPPress>
}> = (data) => {
	const {carousel, articles} = data
	if (!articles || articles.length === 0) return null

	return (
		<div className={styles.content()}>
			<div className={styles.content.background()}>
				<Theme.Container>
					<div className={styles.content.items()}>
						<Carousel {...carousel} full>
							{articles.map((item, i) => (
								<div
									key={i}
									className={styles.content.items.item.is({
										active: carousel.isActive(i)
									})()}
								>
									<Imagepreview
										tabIndex={carousel.isActive(i) ? 0 : -1}
										{...item}
									/>
								</div>
							))}
							<div className={styles.content.items.item()}>
								<ArticlesContentExtra />
							</div>
						</Carousel>
					</div>
				</Theme.Container>
			</div>
		</div>
	)
}

export const ArticlesContentExtra: React.FC = () => {
	const {readingroom} = useLinks()
	const t = useTranslation()

	return (
		<Link to={readingroom.url} className={styles.contentextra()}>
			<div className={styles.contentextra.content()}>
				<div className={styles.contentextra.content.icon()}>
					<Icon icon="Book" />
				</div>
				<Theme.H4 className={styles.contentextra.content.title()} mod="light">
					{t.article.readmore}
				</Theme.H4>
			</div>
		</Link>
	)
}
