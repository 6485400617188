import fetch from 'unfetch'
import { useState } from 'react'
import useSWR from 'swr'

export const fetcher = url => {
  if (!url) return null
  return fetch(url).then(r => r.json())
}

export function useFetcher<D = any>(url): { data: D, isLoading: boolean } {
  const [prevData, setPrevData] = useState<D>()
  const { data } = useSWR<D>(url, fetcher, {
    onSuccess: data => setPrevData(data)
  })
  return {
    data: data || prevData,
    isLoading: !data
  }
}
