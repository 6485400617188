import {DPSpecificationItemBlock, DPSpecificationsBlock} from 'types/DPTypes'

import AnimateHeight from 'react-animate-height'
import {Block} from 'blocks/block'
import {Theme} from 'layout/theme'
import {WysiwygType} from 'types/basictypes'
import css from './specifications.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useState} from 'react'

const styles = fromModule(css)

export type SpecificationsType = {
	items: Array<SpecificationsItemType>
}

type SpecificationsItemType = {
	label: string
	value: WysiwygType
}

export const Specifications: React.FC<DPSpecificationsBlock> = ({
	_type,
	title,
	specification_items
}) => {
	const items = specification_items
	const [isOpen, setOpen] = useState(true)
	if (!items || items.length === 0) return null

	const firstHalft = items.slice(
		0,
		items.length === 1 ? 1 : Math.floor(items.length / 2)
	)
	const secondHalf =
		items.length === 1
			? []
			: items.slice(Math.floor(items.length / 2), items.length)

	return (
		<Block type={_type} className={styles.specifications()}>
			<Theme.Container>
				<div className={styles.specifications.title()}>
					<a onClick={() => setOpen(!isOpen)}>
						<Theme.H2
							className={styles.specifications.title.text.is({
								open: isOpen
							})()}
							mod={['blueline', 'toggle']}
						>
							{title || 'Specifications'}
						</Theme.H2>
					</a>
				</div>
				<AnimateHeight height={isOpen ? 'auto' : 0}>
					<div className={styles.specifications.items()}>
						<div className={styles.specifications.items.col()}>
							{firstHalft.map((item: DPSpecificationItemBlock) => (
								<SpecificationsItem key={item.label} {...item} />
							))}
						</div>
						{secondHalf && secondHalf.length !== 0 && (
							<div className={styles.specifications.items.col()}>
								{secondHalf.map((item: DPSpecificationItemBlock) => (
									<SpecificationsItem key={item.label} {...item} />
								))}
							</div>
						)}
					</div>
				</AnimateHeight>
			</Theme.Container>
		</Block>
	)
}

const SpecificationsItem: React.FC<DPSpecificationItemBlock> = ({
	label,
	value
}) => {
	if (!label && !value) return null

	return (
		<div className={styles.item()}>
			<div className={styles.item.row()}>
				<div className={styles.item.label()}>{label && <p>{label}</p>}</div>
				<div className={styles.item.value()}>
					{value && <Theme.Wysiwyg>{value}</Theme.Wysiwyg>}
				</div>
			</div>
		</div>
	)
}
