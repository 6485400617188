import {
	NonSupportedAnchorBlockType,
	SupportedAnchorBlockType
} from 'blocks/anchor/anchor'

import {DPBlock} from 'types/DPTypes'
import {HTMLProps} from 'react'
import {styler} from 'util/styler/Styler'

const styles = styler('block')

export type BlockProps =
	| {
			type: NonSupportedAnchorBlockType
			anchorID?: string | null
	  }
	| {
			type: SupportedAnchorBlockType
			anchorID: string | null
	  }

export type BlockFC<B extends DPBlock> = React.FC<Omit<B, 'id' | '_type'>>

export const Block: React.FC<BlockProps & HTMLProps<HTMLDivElement>> = ({
	children,
	type,
	className,
	anchorID,
	...props
}) => {
	const extraProps = anchorID ? {id: anchorID} : {}

	return (
		<div
			{...props}
			className={styles.with(type).with(className)()}
			{...extraProps}
		>
			{children}
		</div>
	)
}
