import { useEffect, useRef } from 'react'

export const useAutohide = (ignore = false) => {
  const refFixed = useRef<HTMLElement>()
  const refSticky = useRef<HTMLElement>()

  const lastScroll = useRef(0)
  const ignoreEffect = useRef(false)
  ignoreEffect.current = ignore

  useEffect(() => {
    const domFixed = refFixed!.current!
    const domSticky = refSticky!.current!


    const scroll = () => {
      if (ignoreEffect.current) return
      if (!domFixed) return

      const y = window.pageYOffset
      const { style } = domSticky

      const isInView = domFixed.getBoundingClientRect().bottom > -200
      const isScrollingDown = lastScroll.current < y
      style.transition = 'transform 0.2s ease-out'

      if (isScrollingDown) {
        if (!isInView) {
          style.transform = `translateY(-100%)`
        } else {
          style.transform = `translateY(0)`
        }
      } else {
        style.position = 'sticky'
        style.transform = `translateY(0)`
      }

      lastScroll.current = y
    }

    window.addEventListener('scroll', () => {
      scroll()
    })

    return () => {
      window.removeEventListener('scroll', scroll)
    }
  }, [])

  return { refFixed, refSticky }
}
