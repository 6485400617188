import {Column, Grid} from 'layout/grid'
import {
	DPRequestBlock,
	DPRequestIdentificationTerm,
	DPRequestTopicTerm
} from 'types/DPTypes'
import {Form, Formik} from 'formik'
import React, {HTMLProps} from 'react'

import {Contact} from 'blocks/contact/contact'
import {Icon} from 'assets/icon'
import {Theme} from 'layout/theme'
import css from './request.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useLinks} from 'util/links'
import {useRouter} from 'next/router'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export type RequestType = {}

type RequestFormData = {
	identification: string
	topic: string
}

export const Request: React.FC<DPRequestBlock> = ({
	id,
	identifications,
	topics
}) => {
	return (
		<Contact
			_type="contact"
			id={id}
			avatar_image={null}
			email={null}
			link={null}
			recipient_name={null}
			text={null}
		/>
	)

	/*const {contact} = useLinks()
	const router = useRouter()
	const t = useTranslation()

	return (
		<div className={styles.request()}>
			<Theme.Container>
				<h3 className={styles.request.title()}>{t.request.title}</h3>
				<Formik<RequestFormData>
					initialValues={{
						identification: '',
						topic: ''
					}}
					onSubmit={(values) => {
						router.push({
							pathname: router.pathname,
							query: {
								slug: contact?.url.split('/').filter((piece) => piece),
								...values,
								origin_url: router.asPath
							}
						})
					}}
				>
					<Form>
						<RequestForm topics={topics} identifications={identifications} />
						<div className={styles.request.contact()}>
							<button className={styles.request.contact.link()} type="submit">
								<p className={styles.request.contact.link.text()}>
									{t.request.contact}
								</p>
								<div className={styles.request.contact.link.circle()}>
									<span className={styles.request.contact.link.circle.icon()}>
										<Icon icon="ArrowRight" />
									</span>
								</div>
							</button>
						</div>
					</Form>
				</Formik>
			</Theme.Container>
		</div>
	)*/
}

export const RequestForm: React.FC<{
	identifications: DPRequestIdentificationTerm[]
	topics: DPRequestTopicTerm[]
}> = (data) => {
	const t = useTranslation()

	return (
		<div className={styles.form()}>
			<Grid columns={1} s={1} m={2} l={2} align="top">
				<Column className={styles.request.identification()}>
					<label
						className={styles.request.identification.title()}
						htmlFor="identification-select"
					>
						{t.request.identification}
					</label>
					<RequestSelect
						options={data.identifications.map((term) => term.name)}
						name="identification"
						required
						id="identification-select"
						aria-label={t.request.identification_arialabel}
					/>
				</Column>
				<Column className={styles.request.topic()}>
					<label
						className={styles.request.topic.title()}
						htmlFor="topic-select"
					>
						{t.request.topics}
					</label>
					<RequestSelect
						options={data.topics.map((term) => term.name)}
						name="topic"
						required
						id="topic-select"
						aria-label={t.request.topics_arialabel}
					/>
				</Column>
			</Grid>
		</div>
	)
}

export const RequestSelect: React.FC<
	HTMLProps<HTMLSelectElement> & {
		name: string
		options: Array<string>
	}
> = ({name, options, ...rest}) => {
	return (
		<select {...rest} className={styles.select()}>
			<option />
			{options.map((item, i) => (
				<option value={item} key={i}>
					{item}
				</option>
			))}
		</select>
	)
}
