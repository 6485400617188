import {Form, Formik} from 'formik'
import {FormField, FormRow} from 'layout/form/form'
import React, {useEffect, useState} from 'react'

import {Block} from 'blocks/block'
import {CONTACT_URL} from 'util/urls'
import {DPContactformBlock} from 'types/DPTypes'
import {RequestForm} from 'blocks/request/request'
import {Theme} from 'layout/theme'
import {WysiwygType} from 'types/basictypes'
import css from './contactform.module.scss'
import fetch from 'unfetch'
import {fromModule} from 'util/styler/Styler'
import {scrollToHash} from 'util/link'
import {slugify} from 'util/slugify'
import {useIDFromAnchorBlock} from 'blocks/anchor/anchor'
import {useLinks} from 'util/links'
import {useRouter} from 'next/router'
import {useStringUrlParams} from 'util/useSearchParams'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

type FormData = {
	identification?: string
	topic?: string
	firstname?: string
	lastname?: string
	email?: string
	phone?: string
	question?: string
}

export const Contactform: React.FC<DPContactformBlock> = (data) => {
	const {_type, company_fields, title, enable_bar, topics, identifications} =
		data
	const router = useRouter()
	const {
		contactblock,
		origin_url,
		contactperson,
		identification,
		subject,
		topic
	} = router.query as Record<string, string>
	const {contactformsubmit} = useLinks()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const id = useIDFromAnchorBlock(data)

	useEffect(() => {
		if (
			contactblock ||
			contactperson ||
			origin_url ||
			identification ||
			topic
		) {
			scrollToHash(id)
		}
	})

	return (
		<Block type={_type} anchorID={id} className={styles.contactform()}>
			<Formik<FormData>
				initialValues={{
					identification,
					topic
				}}
				onSubmit={(values, {setErrors, resetForm}) => {
					console.log(values)

					if (isSubmitting) return null
					setIsSubmitting(true)
					return fetch(CONTACT_URL(), {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							...values,
							contactblock,
							origin_url,
							contactperson,
							subject
						})
					}).then((response) => {
						setIsSubmitting(false)
						if (response.ok) {
							resetForm()
							router.push(contactformsubmit.url)
						} else {
							return response.json().then((errors) => setErrors(errors))
						}
					})
				}}
			>
				<Form>
					{enable_bar && (
						<div className={styles.contactform.request()}>
							<Theme.Container>
								<h3 className={styles.contactform.request.title()}>{title}</h3>
								<RequestForm
									identifications={identifications}
									topics={topics}
								/>
							</Theme.Container>
						</div>
					)}
					<div className={styles.contactform.form()}>
						<Theme.Container>
							{!enable_bar && (
								<Theme.H2
									id={slugify(title)}
									className={styles.form.title()}
									mod="blueline"
								>
									{title}
								</Theme.H2>
							)}
							<ContactformForm title={title} company_fields={company_fields} />
						</Theme.Container>
					</div>
				</Form>
			</Formik>
		</Block>
	)
}

export const ContactformForm: React.FC<{
	title?: string
	company_fields: boolean
}> = (data) => {
	const {company_fields} = data
	const {subject} = useStringUrlParams()
	const t = useTranslation()

	return (
		<div className={styles.form()}>
			<div className={styles.form.content()}>
				<FormRow>
					<FormField
						mod={{50: true}}
						name="firstname"
						required
						label={t.form.firstname}
					/>
					<FormField
						mod={{50: true}}
						name="lastname"
						required
						label={t.form.lastname}
					/>
				</FormRow>
				<FormField name="email" type="email" required label={t.form.email} />
				<FormField
					name="phone"
					type="tel"
					required={process.env.NEXT_PUBLIC_PROJECT_KEY !== 'iclink'}
					label={t.form.phone}
				/>
				{company_fields && (
					<>
						<br />
						<FormField name="company" required label={t.form.company} />
						<FormField name="address" label={t.form.address} />
						<FormRow>
							<FormField
								mod={{50: true}}
								name="zipcode"
								label={t.form.zipcode}
							/>
							<FormField mod={{50: true}} name="city" label={t.form.city} />
						</FormRow>
						<br />
					</>
				)}
				{subject && (
					<FormField
						name="subject"
						fixedValue={subject as string}
						readOnly={true}
						label={t.form.subject}
					/>
				)}
				<FormField
					as="textarea"
					component="textarea"
					name="message"
					required
					label={t.form.message}
				/>
				<div className={styles.form.button()}>
					<Theme.Button as="button" type="submit" mod="rounded">
						<Theme.ButtonText>{t.form.submit}</Theme.ButtonText>
						<Theme.ButtonIcon />
					</Theme.Button>
				</div>
			</div>
		</div>
	)
}

export const ContactformThanks: React.FC<{
	title?: string
	text?: WysiwygType
}> = (data) => {
	const {title, text} = data
	const t = useTranslation()

	return (
		<div className={styles.thanks()}>
			<Theme.Container>
				<Theme.H2 className={styles.thanks.title()}>
					{title ? title : t.form.thanks.title}
				</Theme.H2>
				<Theme.Wysiwyg>{text ? text : t.form.thanks.text}</Theme.Wysiwyg>
			</Theme.Container>
		</div>
	)
}
