import { Paragraph } from 'blocks/paragraph'
import React from 'react'
import { DPPrototypeBlock } from 'types/DPTypes'

export const Prototype: React.FC<DPPrototypeBlock> = ({ blocks }) => {
	return (
		<>
			{blocks.map((block, i) => (
				<Paragraph {...block} key={i} />
			))}
		</>
	)
}