import {DPCalendarBlock, DPEvent} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {Icon} from 'assets/icon'
import {Image} from 'layout/partials/image'
import {Link} from 'util/link'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './calendar.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useDpDateToPrettyDate} from 'util/dates'
import {useLinks} from 'util/links'

const styles = fromModule(css)

export const Calendar: React.FC<DPCalendarBlock> = (data) => {
	const {_type, title, events, content, highlighted_image} = data
	const {calendar} = useLinks()

	if (!events || events.length === 0) return null

	if (content)
		return (
			<CalendarContentView
				events={events.slice(0, 3)}
				title={title || calendar.title}
				content={content}
				highlighted_image={highlighted_image}
				calendarurl={calendar.url}
			/>
		)

	return (
		<Block type={_type} className={styles.calendar()}>
			<Theme.Container>
				<Link to={calendar.url}>
					<Theme.H2 mod={['blueline', 'arrow']}>
						{title || calendar.title}
					</Theme.H2>
				</Link>
				<div className={styles.calendar.block()}>
					<CalendarEvents events={events.slice(0, 6)} />
				</div>
			</Theme.Container>
		</Block>
	)
}

const CalendarContentView: React.FC<{
	events: DPEvent[]
	title: string
	content: string
	highlighted_image: DPCalendarBlock['highlighted_image']
	calendarurl: string
}> = ({events, title, content, highlighted_image, calendarurl}) => {
	return (
		<div className={styles.calendar.mod('contentview')()}>
			<div className={styles.contentview()}>
				<div className={styles.contentview.list()}>
					<Link to={calendarurl}>
						<Theme.H2 mod={['blueline', 'arrow', 'white']}>{title}</Theme.H2>
					</Link>
					<CalendarEvents events={events} contentview={true} />
				</div>
				<div className={styles.contentview.content()}>
					{highlighted_image?.src && (
						<Image {...highlighted_image} fill sizes="100vw" />
					)}
					{highlighted_image?.src && (
						<span className={styles.contentview.content.overlay()} />
					)}
					<Theme.Wysiwyg
						className={styles.contentview.content.text.mod({
							image: highlighted_image?.src
						})()}
					>
						{content}
					</Theme.Wysiwyg>
				</div>
			</div>
		</div>
	)
}

export const CalendarEvents: React.FC<{
	events: Array<DPEvent>
	contentview?: boolean
}> = (data) => {
	const {events, contentview} = data
	const {printDate} = useDpDateToPrettyDate()
	if (!events || events.length === 0) return null

	return (
		<div className={styles.events.mod({contentview})()}>
			{events.map((event, i) => (
				<Link
					key={i}
					to={event.url || ''}
					className={styles.events.item.mod({contentview})()}
				>
					<div className={styles.events.item.wrapper()}>
						{event.date && (
							<div className={styles.events.item.date()}>
								{printDate(event.date, 'd MMM yyyy')}
							</div>
						)}
						{event.end_date && (
							<div className={styles.events.item.date.end()}>
								{' '}
								- {printDate(event.end_date, 'd MMM yyyy')}
							</div>
						)}
						<Theme.H4 mod="light">{event.title}</Theme.H4>
						{event?.location && (
							<p className={styles.events.item.location()}>
								<span className={styles.events.item.location.icon()}>
									<Icon icon="Location" />
								</span>
								{event.location}
							</p>
						)}
					</div>
				</Link>
			))}
		</div>
	)
}
