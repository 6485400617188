import {Block} from 'blocks/block'
import {DPEloquaBlock} from 'types/DPTypes'
import InnerHTML from 'dangerously-set-html-content'
import React from 'react'
import {Theme} from '../../layout/theme'
import css from './eloqua.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'blocks/anchor/anchor'
import {useStringUrlParams} from 'util/useSearchParams'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const Eloqua: React.FC<DPEloquaBlock> = (data) => {
	const t = useTranslation()
	const stringParams = useStringUrlParams()
	const anchorID = useIDFromAnchorBlock(data)
	const {
		_type,
		title,
		id,
		url: originalUrl,
		arialabel,
		is_contact_form,
		blocks,
		persons
	} = data
	const {
		contactblock,
		origin_url,
		utm_source,
		utm_campaign,
		utm_medium,
		contactperson
	} = stringParams
	const utmFields = {utm_source, utm_campaign, utm_medium}

	let url = originalUrl
	let paramsObj: Record<string, string> = {
		...stringParams,
		pageURL: origin_url
	}

	if (is_contact_form) {
		if (blocks[`b${contactblock}`]) {
			paramsObj['contactName'] = blocks[`b${contactblock}`].name
			paramsObj['contactEmail'] = blocks[`b${contactblock}`].email
		}
		if (persons[`p${contactperson}`]) {
			paramsObj['contactName'] = persons[`p${contactperson}`].name
			paramsObj['contactEmail'] = persons[`p${contactperson}`].email
		}
	}

	Object.keys(utmFields).forEach((key) => {
		const value = utmFields[key]
		if (!value) return
		paramsObj[key] = value
	})

	const params = Object.keys(paramsObj)
		.filter((key) => paramsObj[key])
		.map((key) => `${key}=${encodeURIComponent(paramsObj[key])}`)
		.join('&')

	url += url.includes('?') ? `&${params}` : `?${params}`

	const ariaLabel = arialabel || t.eloqua.iframe_arialabel

	return (
		<Block type={_type} anchorID={anchorID} className={styles.eloqua()}>
			<Theme.Container>
				{title && (
					<Theme.H2 className={styles.eloqua.title()} mod="blueline">
						{title}
					</Theme.H2>
				)}
				<InnerHTML
					className={styles.eloqua.formcontainer()}
					html={`
          <iframe
              id="form${id}"
              class="eloqua-form"
			  aria-label="${ariaLabel}"
              allowfullscreen=""
              frameborder="0"
              marginheight="0px"
              marginwidth="0px"
              name="Test iframe height"
              scrolling="no"
              src="${url}";
              style="border:0px #ffffff none;" width="100%"
          ></iframe>
          <script
            src="https://images.communication.imec-int.com/Web/InteruniversitairMicroElectronicaCentrum/%7Bd9cbc1b6-0dd5-40bf-972a-fee75c86ed41%7D_embed.js"
            type="application/javascript"
          ></script>
        `}
				/>
			</Theme.Container>
		</Block>
	)
}
