import css from './footernav.module.scss'
import { fromModule } from 'util/styler/Styler'

import { Theme } from 'layout/theme'
import React from 'react'
import { DPFooterCategorieTerm, DPFooterMenuTerm, DPHierarchy, DPLink } from 'types/DPTypes'
import { useTranslation } from 'util/i18'
import { Link } from 'util/link'

const styles = fromModule(css)

export const Footernav: React.FC<{
	categories: DPFooterCategorieTerm[]
	menu: DPFooterMenuTerm[]
}> = (data) => {
	const { categories, menu } = data

	return (
		<div className={styles.footernav()}>
			<div className={styles.footernav.row()}>
				<FooternavCategories items={categories} />
				<FooternavOther items={menu} />
			</div>
		</div>
	)
}

export const FooternavCategories: React.FC<{
	items: DPFooterCategorieTerm[]
}> = ({ items }) => {
	if (!items?.length) return null

	return (
		<div className={styles.categories()}>
			{items.map((category, i) => {
				if (!category.name) return null
				return (
					<div key={i} className={styles.categories.item()}>
						<Theme.H4 className={styles.categories.item.title()} mod="medium">
							<Link to={category.link_title?.url}>{category.name}</Link>
						</Theme.H4>
						{category.footer_category_subtitle && (
							<p className={styles.categories.item.description()}>
								{category.footer_category_subtitle}
							</p>
						)}
						<FooternavLinks
							links={category.footer_category_links}
						/>
					</div>
				)
			})}
		</div>
	)
}

export const FooternavOther: React.FC<{
	items: DPFooterMenuTerm[]
}> = ({ items }) => {
	const { footer: { more_imec } } = useTranslation()

	if (!items?.length) return null

	return (
		<div className={styles.other()}>
			<Theme.H5>{more_imec}</Theme.H5>
			<FooternavLinks
				links={items.map(item => ({ title: item.name, url: item.link?.url }))}
			/>
		</div>
	)
}

export const FooternavLinks: React.FC<{
	links: DPLink[]
}> = ({ links }) => {
	if (!links?.length) return null

	return (
		<div className={styles.links()}>
			{links.map((link, i) => (
				<div key={i} className={styles.links.item()}>
					<FooternavLink key={i} url={link.url} title={link.title} />
				</div>
			))}
		</div>
	)
}

export const FooternavLink: React.FC<{
	url: string
	title: string
}> = ({ url, title }) => {
	if (!url || !title) return null

	return (
		<Link to={url} className={styles.link()}>
			{title}
		</Link>
	)
}
