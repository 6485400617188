import {Icon} from 'assets/icon'
import {Mod} from 'util/style'
import React from 'react'
import css from './sociallink.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Sociallink: React.FC<{
	type: string
	url: string
	mod?: Mod<'rounded' | 'round'>
}> = ({type, url, mod}) => {
	if (!type || !url) return null

	return (
		<a
			href={url}
			target="_blank"
			rel="noreferrer"
			title={socialLinks[type]}
			aria-label={socialLinks[type]}
			className={styles.sociallink.mod([type, mod])()}
		>
			<Icon icon={socialLinks[type]} mod="maxwidth" />
		</a>
	)
}

export const SocialLinkIcon: React.FC<{type: string}> = ({type}) => {
	switch (type) {
		case 'facebook':
			return <Icon icon="Facebook" mod="maxwidth" />
		case 'flickr':
			return <Icon icon="Flickr" mod="maxwidth" />
		case 'instagram':
			return <Icon icon="Instagram" mod="maxwidth" />
		case 'linkedin':
			return <Icon icon="LinkedIn" mod="maxwidth" />
		case 'swivle':
			return <Icon icon="Swivle" mod="maxwidth" />
		case 'twitter':
			return <Icon icon="Twitter" mod="maxwidth" />
		case 'vimeo':
			return <Icon icon="Vimeo" mod="maxwidth" />
		case 'youtube':
			return <Icon icon="YouTube" mod="maxwidth" />
		default:
			return null
	}
}

const socialLinks = {
	facebook: 'Facebook',
	flickr: 'Flickr',
	instagram: 'Instagram',
	linkedin: 'LinkedIn',
	swivle: 'Swivle',
	twitter: 'Twitter',
	vimeo: 'Vimeo',
	youtube: 'YouTube'
}
