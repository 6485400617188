import {DPAgendaBlock, DPDate, DPEvent} from 'types/DPTypes'
import React, {useState} from 'react'

import {Block} from 'blocks/block'
import {Link} from 'util/link'
import {Theme} from 'layout/theme'
import css from './agenda.module.scss'
import {dpDateToJsDate} from 'util/dates'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'blocks/anchor/anchor'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const Agenda: React.FC<DPAgendaBlock> = (data) => {
	const [moreCounter, updateCounter] = useState(1)
	const t = useTranslation()
	const id = useIDFromAnchorBlock(data)

	const allEvents = data.events
	const events = allEvents.slice(0, moreCounter * 10)

	return (
		<Block type={data._type} anchorID={id} className={styles.agenda()}>
			<AgendaEvents events={events} />
			{moreCounter < 5 && allEvents.length !== events.length && (
				<div className={styles.agenda.more()}>
					<div
						className={styles.agenda.more.button()}
						onClick={() => updateCounter(moreCounter + 1)}
					>
						{t.agenda.readmore}
					</div>
				</div>
			)}
		</Block>
	)
}

export const AgendaEvents: React.FC<{
	events: Array<DPEvent>
}> = (data) => {
	const {events} = data
	if (!events || events.length === 0) {
		return <AgendaEmpty />
	}

	return (
		<div className={styles.events()}>
			{events.map((event) => (
				<Event {...event} key={event.node_id} />
			))}
		</div>
	)
}

export const AgendaEmpty: React.FC = () => {
	const t = useTranslation()

	return (
		<div className={styles.empty()}>
			<Theme.Container>
				<Theme.H3 className={styles.empty.title()}>
					{t.agenda.no_results}
				</Theme.H3>
			</Theme.Container>
		</div>
	)
}

export const Event: React.FC<DPEvent> = (data) => {
	const {title, location, date, end_date, url, description, hide_description} =
		data

	const eventInfo = createEventInfo({date, end_date, location})

	return (
		<>
			<div className={styles.events.space()} />
			<Link to={url} className={styles.events.event()}>
				<Theme.Container>
					{date && <h3 className={styles.events.event.date()}>{eventInfo}</h3>}
					<h2>{title}</h2>
					{description && !hide_description && (
						<Theme.Wysiwyg className={styles.events.event.description()}>
							{description}
						</Theme.Wysiwyg>
					)}
				</Theme.Container>
			</Link>
		</>
	)
}

export const createEventInfo = (params: {
	date: DPDate
	end_date: DPDate
	location: string
}) => {
	const {date, end_date, location} = params

	let eventInfo = ''
	if (date) {
		eventInfo = eventInfo.concat(cleanDate(date, end_date))
		if (location) eventInfo = eventInfo.concat(' | ')
	}
	if (location) eventInfo = eventInfo.concat(location)

	return eventInfo
}

const cleanDate = (date, endDateInitial?) => {
	const options = {year: 'numeric', month: 'long', day: 'numeric'} as const
	const optionsMonth = {month: 'long'} as const
	const locale = process.env.NEXT_PUBLIC_DRUPAL_LANG === 'nl' ? 'nl-NL' : 'en'

	const year = date.substring(0, 4)
	const month = date.substring(5, 7)
	const day = date.substring(8, 10)

	const startDate = dpDateToJsDate(date)
	const startDateString = startDate.toLocaleDateString(locale, options)

	if (!endDateInitial) {
		return startDateString
	}

	const endYear = endDateInitial.substring(0, 4)
	const endMonth = endDateInitial.substring(5, 7)
	const endDay = endDateInitial.substring(8, 10)

	const endDate = new Date(endYear, endMonth - 1, endDay)

	if (month === endMonth && year === endYear) {
		const monthString = startDate.toLocaleDateString(locale, optionsMonth)
		return day + ' - ' + endDay + ' ' + monthString + ' ' + year
	}

	if (year === endYear) {
		const startMonthString = startDate.toLocaleDateString(locale, optionsMonth)
		const endMonthString = endDate.toLocaleDateString(locale, optionsMonth)
		return (
			day +
			' ' +
			startMonthString +
			' - ' +
			endDay +
			' ' +
			endMonthString +
			' ' +
			year
		)
	}

	return startDateString.concat(
		' - ',
		endDate.toLocaleDateString(locale, options)
	)
}
