import {Column, Grid} from 'layout/grid'
import {DPDownloadsBlock, DPUrl} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {Icon} from 'assets/icon'
import {Link} from 'util/link'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './downloads.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export type DownloadsType = {
	lightmode?: boolean
	title?: string
	items: Array<DownloadsItemType>
}

export type DownloadsItemType = {
	type: 'file' | 'link'
	url?: DPUrl
	title?: string
}

export const Downloads: React.FC<DPDownloadsBlock> = (data) => {
	const {_type, lightmode, title, items: pItems} = data
	const t = useTranslation()

	const items = []

	;(pItems || []).forEach((pItem) => {
		if (pItem._type === 'downloads_file')
			items.push({
				type: 'file',
				url: pItem.file?.url,
				title: pItem.title
			})
		if (pItem._type === 'downloads_link')
			items.push({
				type: pItem.filesymbol ? 'file' : 'link',
				url: pItem.downloads_link?.url,
				title: pItem.downloads_link?.title
			})
	})

	return (
		<Block type={_type} className={styles.downloads.mod({light: lightmode})()}>
			<Theme.Container>
				<Theme.H2
					className={styles.downloads.title()}
					mod={{blueline: lightmode}}
				>
					{title || t.downloads.title}
				</Theme.H2>
				<div className={styles.downloads.content()}>
					<Grid
						columns={1}
						xxs={{gap: [5, 0]}}
						xs={{gap: [15, 0]}}
						s={{gap: [30, 0]}}
						m={{columns: 2, gap: [65, 0]}}
						l={{columns: 2, gap: [135, 0]}}
						gap={[65, 0]}
					>
						{(items || []).map((item, i) => (
							<Column key={i}>
								<DownloadsItem
									type={item.type}
									title={item.title}
									url={item.url}
								/>
							</Column>
						))}
					</Grid>
				</div>
			</Theme.Container>
		</Block>
	)
}

const DownloadsItem: React.FC<DownloadsItemType> = (data) => {
	const {type, url, title} = data
	if (!url) return null

	return (
		<div className={styles.item()}>
			<Link to={url} className={styles.item.link()}>
				<div className={styles.item.link.icon()}>
					<Icon icon={type === 'file' ? 'File' : 'ArrowDown'} />
				</div>
				<p className={styles.item.link.text()}>{title}</p>
			</Link>
		</div>
	)
}
