import { blocks } from "blocks"
import { DPBlock } from "types/DPTypes"

export const Paragraph: React.FC<DPBlock> = (block) => {
	const Component = blocks[block._type]
	if (!Component) {
		return null
		//TODO next: uncomment
		//throw new Error(`No Block component defined for ${block.type}`)
	}
	return <Component {...block} />
}
