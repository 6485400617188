import 'blocks/blocks.global.scss'
import 'layout/globals.scss'
import 'layout/search/autosuggestwrapper.scss'
import 'layout/theme.global.scss'
import 'styles/algolia.scss'

import {AppContextProvider, AppLanguage} from 'AppContext'
import {DPFullNode, DPGeneral, DPMetaBlock} from 'types/DPTypes'

import {AdminTools} from 'layout/admin/AdminTools'
import type {AppProps} from 'next/app'
import Head from 'next/head'
import {Layout} from 'layout/layout'
import {PING_URL} from 'util/urls'
import PreviewPage from './preview/[lang]'
import TagManager from 'react-gtm-module'
import unfetch from 'unfetch'
import {useEffect} from 'react'

function _app(props: AppProps<any>) {
	const {Component, pageProps} = props
	const general: DPGeneral = pageProps.general
	const node: DPFullNode | null = pageProps.node
	const meta: DPMetaBlock = node?.meta
	const lang: AppLanguage =
		node?.langcode ||
		(process.env.NEXT_PUBLIC_DRUPAL_LANG as AppLanguage) ||
		'en'
	const metaImage = meta?.image || meta?.image_placeholder

	useEffect(() => {
		if (!node) return
		const pingUrl = PING_URL(node?.node_id)
		if (pingUrl) unfetch(pingUrl)
	}, [node])

	useEffect(() => {
		if (!process.env.NEXT_PUBLIC_GTM_ID) return
		TagManager.initialize({gtmId: process.env.NEXT_PUBLIC_GTM_ID})
	}, [])

	if (!general) {
		return 'An error occured'
	}

	if (Component === PreviewPage) {
		return (
			<>
				<Head>
					<meta charSet="utf-8" />
					<meta
						name="viewport"
						content="initial-scale=1.0, width=device-width"
					/>
					<meta name="language" content={lang} />
				</Head>
				<PreviewPage {...props} />
			</>
		)
	}

	return (
		<div className="app">
			<Head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
				<meta name="language" content={lang} />
				{process.env.NODE_ENV === 'production' ? (
					<meta name="robots" content="index, follow" />
				) : (
					<meta name="robots" content="noindex, nofollow" />
				)}
				{process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION && (
					<meta
						name="google-site-verification"
						content={process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION}
					/>
				)}
				{node && (
					<link
						rel="canonical"
						href={process.env.NEXT_PUBLIC_WEBSITE_URL + node?.url}
					/>
				)}
				<link rel="shortcut icon" href="/favicon.ico" />
				{meta && (
					<>
						<title>{meta.title || meta.title_placeholder}</title>
						<meta property="og:type" content="website" />
						<meta
							property="og:url"
							content={process.env.NEXT_PUBLIC_WEBSITE_URL + node.url}
						/>
						<meta
							property="og:title"
							content={meta.title || meta.title_placeholder}
						/>
					</>
				)}
				{(meta?.description || meta?.desc_placeholder) && (
					<>
						<meta
							name="description"
							content={meta.description || meta.desc_placeholder}
						/>
						<meta
							property="og:description"
							content={meta.description || meta.desc_placeholder}
						/>
					</>
				)}
				{metaImage && (
					<>
						<meta
							property="og:image"
							content={process.env.NEXT_PUBLIC_WEBSITE_URL + metaImage.src}
						/>
						<meta property="og:image:width" content={'' + metaImage.width} />
						<meta property="og:image:height" content={'' + metaImage.height} />
						<meta name="twitter:card" content="summary_large_image" />
					</>
				)}
			</Head>
			<AppContextProvider general={general} node={node} lang={lang}>
				{node && <AdminTools node={node} />}
				{node?._type !== 'article' && (
					<Layout>
						<Component {...pageProps.node} custom404s={pageProps.custom404s} />
					</Layout>
				)}
				{node?._type === 'article' && <Component {...pageProps.node} />}
			</AppContextProvider>
		</div>
	)
}

export default _app
