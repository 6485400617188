import NextImage, {ImageProps} from 'next/image'

import {DPImage} from 'types/DPTypes'
import css from './image.module.scss'
import {fromModule} from 'util/styler/Styler'
import {getPathBasename} from 'util/path'

const styles = fromModule(css)

// https://nextjs.org/docs/api-reference/next/image#sizes

export const Image: React.FC<
	DPImage & ImageProps & {sizes: ImageProps['sizes']}
> = ({overlay_percentage, use_dark_colors, ...props}) => {
	if (!props?.src) return null

	if (props.fill)
		return (
			<NextImage
				{...props}
				width={undefined}
				height={undefined}
				style={props.style || {objectFit: 'cover'}}
				className={styles.image()}
				alt={props.alt || getPathBasename(props.src)}
			/>
		)

	return (
		<NextImage
			{...props}
			className={styles.image()}
			alt={props?.alt || getPathBasename(props.src)}
		/>
	)
}
