import {DPVideoblockBlock, DPWysiwyg} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {CookieMissing} from 'layout/cookies/cookiemissing'
import React from 'react'
import {Theme} from 'layout/theme'
import {Trusted} from 'util/trusted'
import css from './videoblock.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'blocks/anchor/anchor'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const VideoBlock: React.FC<
	DPVideoblockBlock & {caption?: DPWysiwyg; title?: string; overview?: boolean}
> = (data) => {
	const {_type, embed_code, size, caption, title, overview} = data
	const t = useTranslation()
	const id = useIDFromAnchorBlock(data)

	if (!embed_code) return null

	return (
		<Block
			type={_type}
			anchorID={id}
			className={styles.videoblock.mod({overview})()}
		>
			<Theme.Container>
				{title && (
					<Theme.H2 className={styles.videoblock.title()} mod="blueline">
						{title}
					</Theme.H2>
				)}
				<div className={styles.videoblock.content.mod(size?.name)()}>
					<div className={styles.videoblock.iframecontainer()}>
						<CookieMissing object={embed_code}>
							<Trusted>{embed_code}</Trusted>
						</CookieMissing>
					</div>
					{caption && (
						<Theme.Wysiwyg className={styles.videoblock.caption()}>
							{caption}
						</Theme.Wysiwyg>
					)}
				</div>
			</Theme.Container>
		</Block>
	)
}
