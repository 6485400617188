import {DPNode} from 'types/DPTypes'

export const nl = {
	search: {
		all: 'Alle',
		categories_label: 'Filter resultaten:',
		categories: {
			press: 'Persberichten',
			article: 'Artikelen',
			job: 'Jobs',
			event: 'Evenementen'
		}
	},
	getNodeLabel: (node: DPNode): string => {
		switch (node._type) {
			case 'page':
				return 'Page'
			case 'product':
				return 'Product'
			case 'event':
				return 'Evenement'
			case 'job':
				return 'Job'
			case 'press':
				return 'Persbericht'
			case 'research_project':
				return 'imec.icon'
			case 'dossier':
				return 'Dossier'
			case 'video_page':
				return 'Video'
			case 'case':
				return node.label?.name
			case 'article':
				return node.label?.name
			default:
				return ''
		}
	},
	read_more: 'Lees meer',
	read_more_arialabel: 'Lees meer over ',
	agenda: {
		no_results: 'Er zijn momenteel geen evenementen gepland op onze agenda',
		readmore: 'Toon meer'
	},
	presslabel: 'Persbericht',
	iconproject: {
		read_more: 'Lees meer'
	},
	footer: {
		follow: 'Volg imec op:',
		partof: 'is deel van',
		more_imec: 'Meer imec',
		hyperspectral: 'Hyperspectral imaging is deel van',
		iclink: 'imec.IC-link is deel van'
	},
	newsletter: {
		placeholder: 'Jouw email adres',
		placeholder_success: 'Bedankt voor uw inschrijving!',
		signup: 'Inschrijven'
	},
	jobs: {
		periods: {
			past_day: 'Past day',
			past_3days: 'Past 3 days',
			past_week: 'Past week',
			past_month: 'Past month'
		},
		filters: {
			categories: 'Category',
			job_title: 'Title',
			region: 'Location',
			employment_type: 'Employment Types',
			employer: 'Employer',
			type: 'Position type',
			promotor: 'Promotor',
			csr: 'CSR-label',
			competence: 'Competence',
			marketsegments: 'Market segments',
			date_posted: 'Date Posted'
		}
	},
	readingroom: {
		popular_topics: 'Populaire onderwerpen',
		related: 'Partner gerelateerd',
		clear: 'Verwijder filters',
		searchbar: 'Zoek op onderwerp of thema',
		showmore: 'Onderwerpen',
		showless: 'Toon minder',
		readmore: 'TOON MEER',
		noResultsTitle: 'Jammer!',
		noResultsPart1: 'We vinden niets terug met de trefwoorden ',
		noResultsPart2:
			'. Probeer het gerust nog eens, bijvoorbeeld met een wat algemenere term. Of klik op ‘onderwerpen’ om te filteren per thema.',
		extra: {
			blocks: {
				name: 'Extra categorie label',
				items: [
					{
						type: 'pagetype' as const,
						pagetype: 'press',
						name: 'Persberichten'
					},
					{
						type: 'tag' as const,
						name: 'DUMMY [tag] Child'
					},
					{
						type: 'partner_related' as const,
						name: 'Partner gerelateerd'
					},
					{
						type: 'label' as const,
						name: 'Featured in the media'
					}
				]
			},
			vlaanderen: {
				name: 'Over imec',
				items: [
					{
						type: 'tag' as const,
						name: 'Algemeen'
					},
					{
						type: 'pagetype' as const,
						pagetype: 'press',
						name: 'Persberichten'
					},
					{
						type: 'partner_related' as const,
						name: 'Partner gerelateerd'
					},
					{
						type: 'tag' as const,
						name: 'Duurzame ontwikkeling'
					},
					{
						type: 'label' as const,
						name: 'In de media'
					}
				]
			},
			corporate: {
				name: 'Over imec',
				items: [
					{
						type: 'tag' as const,
						name: 'Algemeen'
					},
					{
						type: 'pagetype' as const,
						pagetype: 'press',
						name: 'Press releases'
					},
					{
						type: 'partner_related' as const,
						name: 'Partner gerelateerd'
					},
					{
						type: 'tag' as const,
						name: 'Sustainable development'
					},
					{
						type: 'label' as const,
						name: 'Featured in the media'
					}
				]
			}
		},
		textualTags: ['Partner gerelateerd'],
		types: {
			article: 'Blog',
			press: 'Persberichten',
			video_page: 'Video'
		},
		tabs: {
			all: 'Overzicht',
			article: 'Artikelen',
			press: 'Persberichten',
			video_page: 'Videos'
		},
		popular: {title: 'Meest gelezen'}
	},
	header: {
		search: {
			toggle_arialabel: 'Open zoekfunctie',
			search_arialabel: 'Zoek'
		},
		hamburger: {
			open_arialabel: 'Open mobiel menu',
			close_arialabel: 'Sluit mobiel menu'
		},
		request: 'Vraag meer informatie aan',
		back: 'Terug naar overzicht'
	},
	article: {
		back: 'Naar Stories',
		short: 'In het kort',
		readmore: 'Lees meer'
	},
	press: {
		title: 'Persbericht',
		readmore: 'Lees meer'
	},
	sidebar: {
		mobile_arialabel: 'Mobiel telefoonnummer',
		phone_arialabel: 'Telefoonnummer',
		email_arialabel: 'Mail naar',
		tags: 'Meer over de volgende onderwerpen',
		date: 'Gepubliceerd op'
	},
	form: {
		firstname: 'Voornaam',
		lastname: 'Achternaam',
		email: 'E-mailadres',
		phone: 'Telefoonnummer',

		company: 'Bedrijf/organisatie',
		address: 'Adres bedrijf',
		zipcode: 'Zip code',
		city: 'Stad',

		subject: 'Onderwerp',
		message: 'Uw bericht',
		submit: 'Verstuur',
		thanks: {
			title: 'Bedankt!',
			text: 'Het formulier werd succesvol verzonden.'
		}
	},
	request: {
		title: 'Hoe kunnen we u helpen?',
		identification: 'Stuur ons uw vraag',
		identification_arialabel:
			'Stuur ons uw vraag: Selecteer je organisatietype',
		topics: 'Ik ben op zoek naar',
		topics_arialabel: 'Ik ben op zoek naar: Selecteer een onderwerp',
		contact: 'Neem contact met ons op'
	},
	iconoverview: {
		startyear: 'Beginjaar',
		market: 'Markt',
		projectType: 'Research project type',
		status: 'status',
		readmore: 'lees meer',
		filters: {
			market: 'Markt',
			research_project_type: 'Research project type',
			status: 'Status',
			year: 'Beginjaar'
		}
	},
	filters: {
		clear: 'verwijder filters'
	},
	downloads: {
		title: 'Download',
		text: 'Download file'
	},
	iframe: {
		invisible:
			'Deze content is enkel zichtbaar op de desktop versie van de website.'
	},
	researchproject: {
		about: 'Over het project'
	},
	job: {
		apply: 'Apply',
		form: {
			title: 'Verzend deze job naar jouw e-mailadres',
			thanks: 'E-mail is verzonden'
		}
	},
	words: {
		show_all: 'Toon alle'
	},
	jobalerts: {
		title: 'Job notificaties',
		unsubscribed: {
			title: 'Stopzetten',
			text: 'We hebben de notificaties stopgezet voor volgende zoektermen:',
			empty: 'We hebben al uw notificaties stopgezet',
			view: {
				job: 'Bekijk job aanbiedingen',
				academic: 'Bekijk job aanbiedingen'
			},
			add: 'Voeg job notificatie toe'
		}
	},
	share: 'Deel deze pagina op',
	social: {
		facebook: 'Facebook',
		linkedin: 'LinkedIn',
		twitter: 'Twitter'
	},
	cookies: {
		placeholder: {
			text: (category) =>
				`Accepteer ${category}-cookies om deze content te kunnen bekijken.`,
			button: 'Cookie-instellingen'
		}
	},
	gallery: {
		image_arialabel: 'Download/bekijk afbeelding'
	},
	carousel: {
		index_arialabel: 'Carrousel: Ga naar bepaalde index',
		prev_arialabel: 'Carrousel: Ga naar vorige',
		next_arialabel: 'Carrousel: Ga naar volgende'
	},
	select: 'Selecteer: ',
	eloqua: {
		iframe_arialabel: 'Registratieformulier'
	},
	dossier: 'Dossier',
	euprojects: {
		title: 'Europese projecten',
		domain: 'Expertise domein',
		filters: {
			domain: 'Expertise',
			scope: 'EU/Vlaams'
		},
		scopes: {
			europe: 'EU',
			flemish: 'Vlaams'
		}
	}
}
