import {Carousel, useCarousel} from '@codeurs/carousel'
import {
	CarouselControlsNext,
	CarouselControlsPrevious
} from 'layout/carousel/carouselcontrols'
import {
	DPGalleryBlock,
	DPGalleryImageBlock,
	DPGalleryVideoBlock
} from 'types/DPTypes'
import React, {useEffect, useRef} from 'react'

import {BgFixed} from 'layout/partials/bg'
import {Block} from 'blocks/block'
import {CarouselType} from 'types/basictypes'
import {Icon} from 'assets/icon'
import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import css from './gallery.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const Gallery: React.FC<DPGalleryBlock> = (data) => {
	const {_type, items} = data
	const carousel = useCarousel()
	if (!items || items.length === 0) return null

	return (
		<Block type={_type} className={styles.gallery()}>
			<Theme.Container>
				<div className={styles.gallery.container()}>
					<div className={styles.gallery.content()}>
						<div className={styles.gallery.content.previous()}>
							<CarouselControlsPrevious carousel={carousel} />
						</div>
						<div className={styles.gallery.content.carousel()}>
							<Carousel {...carousel}>
								{items.map((item, i) => {
									switch (item._type) {
										case 'gallery_image':
											return (
												<GalleryItemImage
													key={item.id}
													tabIndex={carousel.isActive(i) ? 0 : -1}
													{...item}
												/>
											)
										case 'gallery_video':
											return (
												<GalleryItemVideo
													key={item.id}
													tabIndex={carousel.isActive(i) ? 0 : -1}
													{...item}
												/>
											)
										default:
											return null
									}
								})}
							</Carousel>
						</div>
						<div className={styles.gallery.content.next()}>
							<CarouselControlsNext carousel={carousel} />
						</div>
					</div>
					<GalleryNav carousel={carousel} items={items} />
				</div>
			</Theme.Container>
		</Block>
	)
}

export const GalleryItemImage: React.FC<
	DPGalleryImageBlock & {tabIndex?: number}
> = (data) => {
	const {image, description, tabIndex} = data
	const t = useTranslation()
	if (!image) return null

	return (
		<div className={styles.image.mod('image')()}>
			<BgFixed image={image} sizes="960px" hideOverlay={true} mod="contain" />
			<div className={styles.image.icons()}>
				<a
					href={image?.src}
					target="_blank"
					tabIndex={tabIndex}
					aria-label={t.gallery.image_arialabel}
					className={styles.image.icons.item()}
				>
					<span className={styles.image.icons.item.icon()}>
						<Icon icon="Download" />
					</span>
				</a>
			</div>
			{description && (
				<div className={styles.image.description()}>
					<Theme.Wysiwyg>{description}</Theme.Wysiwyg>
				</div>
			)}
		</div>
	)
}

export const GalleryItemVideo: React.FC<
	DPGalleryVideoBlock & {tabIndex?: number}
> = (data) => {
	const {embed_code, arialabel, tabIndex} = data
	if (!embed_code) return null

	return (
		<div className={styles.video()}>
			<GalleryTrusted ariaLabel={arialabel} tabIndex={tabIndex}>
				{embed_code}
			</GalleryTrusted>
		</div>
	)
}

export const GalleryNav: React.FC<{
	carousel: CarouselType
	items: Array<DPGalleryVideoBlock | DPGalleryImageBlock>
}> = (data) => {
	const {carousel, items} = data

	return (
		<div className={styles.nav()}>
			<div className={styles.nav.items()}>
				{items.map((item, i) => {
					const thumbnail =
						item._type === 'gallery_image' ? item.image : item.thumbnail
					return (
						<button
							key={i}
							onClick={() => carousel.goTo(i)}
							title={
								item._type === 'gallery_video'
									? item.arialabel
									: item.image?.alt
							}
							aria-label={
								item._type === 'gallery_video'
									? item.arialabel
									: item.image?.alt
							}
							className={styles.nav.item.is({
								active: carousel.isActive(i)
							})()}
						>
							{thumbnail && <Image {...thumbnail} sizes="240px" />}
						</button>
					)
				})}
			</div>
		</div>
	)
}

const GalleryTrusted: React.FC<{
	className?: string
	children: string
	ariaLabel?: string
	tabIndex?: number
}> = ({children, ariaLabel, tabIndex, ...props}) => {
	const dom = useRef<HTMLDivElement>()

	useEffect(() => {
		const clear = []

		const iframes: NodeListOf<HTMLIFrameElement> =
			dom?.current?.querySelectorAll('iframe')

		if (iframes?.length) {
			iframes.forEach((iframe) => {
				if (!iframe) return null
				iframe.setAttribute('loading', 'lazy')
				ariaLabel && iframe.setAttribute('title', ariaLabel)
				tabIndex && iframe.setAttribute('tabindex', tabIndex.toString())
			})
		}

		return () => {
			clear.forEach((f) => f())
		}
	})

	return (
		<div
			ref={dom}
			{...props}
			dangerouslySetInnerHTML={{
				__html: Array.isArray(children) ? children[0] : children
			}}
		/>
	)
}
