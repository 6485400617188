import {Block} from 'blocks/block'
import {DPAlertBlock} from 'types/DPTypes'
import {Link} from 'util/link'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './alert.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Alert: React.FC<DPAlertBlock> = ({_type, link, text}) => {
	const LinkTag = link?.url ? Link : 'div'
	const linkProps = link?.url ? {to: link.url} : {}

	if (!text) return null

	return (
		<Block type={_type}>
			<LinkTag {...linkProps} className={styles.alert()}>
				<Theme.Wysiwyg>{text}</Theme.Wysiwyg>
				{link?.title && (
					<Theme.Link as="span" mod="inherit" className={styles.alert.link()}>
						<Theme.LinkText>{link.title}</Theme.LinkText>
						<Theme.LinkIcon />
					</Theme.Link>
				)}
			</LinkTag>
		</Block>
	)
}
