import { JobsSearchModel } from 'blocks/jobs/models/JobsSearchModel'
import { JobFilterModel } from 'blocks/jobs/models/JobFilterModel'
import { JobTermModel } from 'blocks/jobs/models/JobTermModel'
import { JobModel } from 'blocks/jobs/models/JobModel'
import memoizeGetters from 'memoize-getters'

export type PeriodKey = 'past_day' | 'past_3days' | 'past_week' | 'past_month'

export class DatePostedFilterModel extends JobFilterModel {

  constructor(search: JobsSearchModel) {
    super(search)
  }

  get key() {
    return 'date_posted' as const
  }

  get terms(): Array<DatePostedTermModel> {
    return [
      new DatePostedTermModel(this, 'past_day', 1),
      new DatePostedTermModel(this, 'past_3days', 3),
      new DatePostedTermModel(this, 'past_week', 7),
      new DatePostedTermModel(this, 'past_month', 30)
    ]
  }
}

export class DatePostedTermModel extends JobTermModel<DatePostedFilterModel> {
  key: PeriodKey
  days: number

  constructor(filter: DatePostedFilterModel, key: PeriodKey, days: number) {
    super(filter)
    this.key = key
    this.days = days
  }

  get id(): string {
    return this.key
  }

  select(): JobsSearchModel {
    if (this.selected) return this.search
    const termsIDsToDisable = new Set(this.filter.terms.map(term => term.id))
    return this.search.update({
      terms: [...this.search.state.terms.filter(id => !termsIDsToDisable.has(id)), this.id]
    })
  }

  validateJob(job: JobModel): boolean {
    return job.daysPosted <= this.days
  }
}

memoizeGetters(DatePostedFilterModel)
memoizeGetters(DatePostedTermModel)
