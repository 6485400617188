import {Column, Grid} from 'layout/grid'
import {
	DPHierarchy,
	DPParentTerm,
	DPProductCameraTerm,
	DPProductsBlock
} from 'types/DPTypes'
import React, {useState} from 'react'

import AnimateHeight from 'react-animate-height'
import {Block} from 'blocks/block'
import {Cardpreview} from 'layout/preview/cardpreview'
import {FormSimpleCheckbox} from 'layout/form/form'
import {SearchresultsLoader} from 'layout/search/searchresults'
import {Theme} from 'layout/theme'
import css from './products.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useRouter} from 'next/router'
import {useSearchParamsAreLoaded} from 'util/useSearchParamsAreLoaded'

const styles = fromModule(css)

const useProductTerms = () => {
	const router = useRouter()
	const terms = router.query.terms

	if (!terms) return []
	if (Array.isArray(terms)) return terms.map((t) => parseInt(t, 10))
	return [parseInt(terms, 10)]
}

type FilterTerm = DPProductCameraTerm
export const Products: React.FC<DPProductsBlock> = (data) => {
	const loaded = useSearchParamsAreLoaded()

	if (!loaded) return <SearchresultsLoader />
	return <ProductsView {...data} />
}

export const ProductsView: React.FC<DPProductsBlock> = ({
	_type,
	items,
	filters
}) => {
	const terms = new Set(useProductTerms())
	const activeFilters = filters.filter((filter) => {
		return filter.children.find((child) => terms.has(child.id))
	})
	const visibleItems = items.filter((product) => {
		if (!activeFilters.length) return true
		const productValues = new Set(
			(product.type?.product_filters || []).map((value) => value.id)
		)
		const invalidFilter = activeFilters.find((filter) => {
			const acceptableValues = filter.children.filter((child) =>
				terms.has(child.id)
			)
			return !acceptableValues.find((value) => productValues.has(value.id))
		})
		return invalidFilter ? false : true
	})

	return (
		<Block type={_type} className={styles.products()}>
			<Theme.Container>
				<Grid columns={1} xs={1} s={1} m={12} l={12}>
					<Column m={3} l={3}>
						<ProductsFilters filters={filters} />
					</Column>
					<Column m={9} l={9}>
						<div className={styles.products.content()}>
							<Grid columns={1} xs={2} s={2} m={2} l={3} gap={[15, 50]}>
								{visibleItems.map((item) => (
									<Column key={item.url}>
										<Cardpreview
											url={item.url}
											image={item.image}
											text={`<h5>${item.title}</h5 > `}
											text_mod={['small', 'full']}
											display_fallback={false}
										/>
									</Column>
								))}
							</Grid>
						</div>
					</Column>
				</Grid>
			</Theme.Container>
		</Block>
	)
}

const ProductsFilters: React.FC<{
	filters: DPHierarchy<FilterTerm>
}> = ({filters}) => {
	return (
		<div className={styles.filters()}>
			{filters.map((filter) => (
				<ProductsFiltersCategory key={filter.key} filter={filter} />
			))}
		</div>
	)
}

const ProductsFiltersCategory: React.FC<{filter: DPParentTerm<FilterTerm>}> = ({
	filter
}) => {
	const [isOpen, setOpen] = useState(true)

	return (
		<div className={styles.filterscategory()}>
			<Theme.H5
				className={styles.filterscategory.title.is({open: isOpen})()}
				mod="toggle"
			>
				<span onClick={() => setOpen(!isOpen)}>{filter.name}</span>
			</Theme.H5>
			<AnimateHeight height={isOpen ? 'auto' : 0}>
				<div className={styles.filterscategory.items()}>
					{filter.children.map((term) => (
						<div className={styles.filterscategory.items.item()} key={term.id}>
							<ProductsFiltersSubcategory term={term} />
						</div>
					))}
				</div>
			</AnimateHeight>
		</div>
	)
}

const ProductsFiltersSubcategory: React.FC<{
	term: DPParentTerm<FilterTerm>
}> = ({term}) => {
	const terms = useProductTerms()
	const {pathname, query, push} = useRouter()
	const checked = !!terms.find((t) => t === term.id)

	return (
		<>
			<div className={styles.filterssubcategory()}>
				<FormSimpleCheckbox
					name={'p' + term.id}
					label={term.name}
					value={checked}
					onChange={() => {
						const newTerms = new Set(terms).has(term.id)
							? terms.filter((t) => t !== term.id)
							: [...terms, term.id]
						push(
							{
								pathname,
								query: {...query, terms: newTerms}
							},
							null,
							{shallow: true}
						)
					}}
				/>
			</div>
		</>
	)
}
